import React from "react";

export enum DirectoryTab {
  PasskeySites = "passkey-sites",
  RequestedSites = "requested-sites",
}

export interface ApplicationStateProps {
  debug: boolean;
  error: Error | string | undefined;
  loading: boolean;
  directoryTab: DirectoryTab;
}

export type ApplicationAction = { type: "SELECT_TAB"; tab: DirectoryTab };

export interface ApplicationContextProps {
  state: ApplicationStateProps;
  dispatch: React.Dispatch<ApplicationAction>;
  selectDirectoryTab: (tab: DirectoryTab) => void;
}
