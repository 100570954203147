import React from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import {
  PASSKEY_SITES_FILTER_BY,
  PASSKEY_SITES_ORDER_BY,
  usePasskeySites,
} from "../context";

export type SearchInputOnChange = (
  event: React.ChangeEvent<HTMLInputElement>
) => void;

type SelectOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => void;

export const DirectoryFilter: React.FC = () => {
  const {
    setSearchTerm,
    setFilterBy,
    setOrderBy,
    loadAll: fetchAll,
    state: {
      searchTerm,
      passkeySites: sites,
      allPasskeySites: allSites,
      filterBy,
      orderBy,
    },
  } = usePasskeySites();

  const handleSearchInputChange: SearchInputOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchTerm(event.target.value);
  };

  const handleFilterByChange: SelectOnChange = (event) => {
    setFilterBy(event.target.value as PASSKEY_SITES_FILTER_BY);
  };

  const handleOrderByChange: SelectOnChange = (event) => {
    setOrderBy(event.target.value as PASSKEY_SITES_ORDER_BY);
  };

  return (
    <div className="flex flex-col sm:flex-row md:flex-row relative h-full w-full gap-4">
      <div className="flex-1 sm:flex-1 md:flex-3">
        <div className="flex absolute max-h-10 inset-y-0 left-0 items-center pl-1 pointer-events-none">
          <MagnifyingGlassIcon
            className="h-6 w-6 text-zinc-500 ml-2"
            aria-hidden="true"
          />
        </div>
        <input
          type="search"
          inputMode="search"
          id="directory-search"
          value={searchTerm || ""}
          onChange={handleSearchInputChange}
          placeholder="Search passkeys.directory"
          className="w-full block p-2 pl-10 text-sm text-gray-900 bg-gray-50 rounded-lg border cursor-text border-gray-300 focus:ring-blue-500 focus:border-blue-500"
        />
      </div>
      <div>
        <div className="flex flex-row items-center">
          <label
            htmlFor="directory-filter"
            className="text-gray-900 text-sm block mr-2"
          >
            Viewing
          </label>
          <select
            id="directory-filter"
            name="directory-filter"
            value={filterBy}
            onChange={handleFilterByChange}
            className="appearance-none text-gray-500 text-sm border border-gray-300 rounded-lg p-2 bg-gray-50 max-w-fit truncate pr-6"
          >
            <option value={PASSKEY_SITES_FILTER_BY.ALL}>
              {PASSKEY_SITES_FILTER_BY.ALL}
            </option>
            <option value={PASSKEY_SITES_FILTER_BY.SIGN_IN}>
              {PASSKEY_SITES_FILTER_BY.SIGN_IN}
            </option>
            <option value={PASSKEY_SITES_FILTER_BY.MFA}>
              {PASSKEY_SITES_FILTER_BY.MFA}
            </option>
          </select>
        </div>
      </div>
      <div>
        <div className="flex flex-row items-center">
          <label
            htmlFor="directory-orderby"
            className="text-gray-900 text-sm block mr-2"
          >
            Sort by
          </label>
          <select
            name="directory-orderby"
            id="directory-orderby"
            value={orderBy}
            onChange={handleOrderByChange}
            className="appearance-none text-gray-500 text-sm border border-gray-300 px-2 rounded-lg p-2 bg-gray-50 max-w-fit"
          >
            <option value={PASSKEY_SITES_ORDER_BY.NAME}>
              {PASSKEY_SITES_ORDER_BY.NAME}
            </option>
            <option value={PASSKEY_SITES_ORDER_BY.NAME_DESC}>
              {PASSKEY_SITES_ORDER_BY.NAME_DESC}
            </option>
            <option value={PASSKEY_SITES_ORDER_BY.CATEGORY}>
              {PASSKEY_SITES_ORDER_BY.CATEGORY}
            </option>
            <option value={PASSKEY_SITES_ORDER_BY.CATEGORY_DESC}>
              {PASSKEY_SITES_ORDER_BY.CATEGORY_DESC}
            </option>
            <option value={PASSKEY_SITES_ORDER_BY.DATE_RECENT}>
            {PASSKEY_SITES_ORDER_BY.DATE_RECENT}
            </option>
            <option value={PASSKEY_SITES_ORDER_BY.DATE_ORDER}>
              {PASSKEY_SITES_ORDER_BY.DATE_ORDER}
            </option>
          </select>
        </div>
      </div>
    </div>
  );
};
