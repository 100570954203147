interface TypographyStyles {
  headingPage: string;
  body1: string;
}

interface TypographyComponents {
  styles: TypographyStyles;
}

const styles: TypographyStyles = {
  headingPage: "font-sans font-bold text-2xl",
  body1: "font-sans font-normal text-sm text-gray-600",
};

export const Typography: TypographyComponents = {
  styles,
};
