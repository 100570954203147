import * as React from "react";
import { RecordDetails } from "./RecordDetails";
import { directoryRecordKey } from "../../../utils/utils";
import { usePasskeySites } from "../context";
import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-gtag";
import { DetailsPageWrapper, Button, ButtonVariant } from "../../TailWind";

export const RecordDetailsContainer: React.FC<{ companyName: string }> = ({
  companyName,
}) => {
  const {
    state: { passkeySites: sites },
  } = usePasskeySites();
  const record = sites.find(
    (record) => directoryRecordKey(record) === companyName
  );

  if (!record) {
    return (
      <>
      <DetailsPageWrapper>
        <article className="gap-4 flex flex-col pl-12 pr-12 items-center">
          <header className="items-center flex flex-col w-full gap-1 pt-6">
            <h1 className="text-xl font-semibold">This website, app, or service was not found on passkeys.directory</h1>
          </header>

          <section className="flex flex-col justify-start items-start gap-2 pb-4 border-b border-slate-200 w-full">
            <p className="text-left font-normal text-sm text-gray-500">
              If this website, app, or service supports signing in with passkeys, you can suggest it to be added to passkeys.directory.</p>
            <div className="-ml-2 text-sm">
            <OutboundLink href="https://docs.google.com/forms/d/e/1FAIpQLSfsTxXH0jWA3Op4Qbte3tdn2OR0zs8ixLKljWswSI-Gi3V80A/viewform" target="_blank">
              <Button variant={ButtonVariant.Primary}>
                <svg
                  className="text-white w-4 h-4"
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="16"
                    height="16"
                    transform="translate(0.5)"
                    fill="currentColor"
                    fillOpacity="0.01"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.5 2C7.94772 2 7.5 2.44772 7.5 3V7H3.5C2.94772 7 2.5 7.44772 2.5 8C2.5 8.55228 2.94772 9 3.5 9H7.5V13C7.5 13.5523 7.94772 14 8.5 14C9.05228 14 9.5 13.5523 9.5 13V9H13.5C14.0523 9 14.5 8.55228 14.5 8C14.5 7.44772 14.0523 7 13.5 7H9.5V3C9.5 2.44772 9.05228 2 8.5 2Z"
                    fill="currentColor"
                  />
                </svg>
                <span>Suggest new listing</span>
              </Button>
            </OutboundLink>
            </div>
          </section>

          <section className="flex flex-col justify-start items-start gap-2 pb-4  border-b border-slate-200  w-full">
            <p className="text-left font-normal text-sm text-gray-500">
              If you are the owner of this site and you would like to adopt
              passkey support, <a href="https://passage.1password.com/?utm_source=passkey+directory&utm_medium=banner&utm_campaign=passkey-directory" className="underline underline-offset-4 font-normal text-left text-slate-500 text-sm"
              target="_blank">Passage by 1Password can help you</a>.
            </p>
          </section>

          <section className="flex flex-col justify-start items-start gap-2 pb-4  border-b border-slate-200  w-full">
            <p className="text-left font-normal text-sm text-gray-500">
              If this website, app, or service does not yet offer support for passkeys, and you would like to see support added, you can request it on passkeys.directory.
            </p>
            <div className="-ml-2 text-sm">
              <Link to="/request-passkey-support">
              <Button variant={ButtonVariant.Primary}>
                <svg
                  className="text-white w-4 h-4"
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="16"
                    height="16"
                    transform="translate(0.5)"
                    fill="currentColor"
                    fillOpacity="0.01"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.5 2C7.94772 2 7.5 2.44772 7.5 3V7H3.5C2.94772 7 2.5 7.44772 2.5 8C2.5 8.55228 2.94772 9 3.5 9H7.5V13C7.5 13.5523 7.94772 14 8.5 14C9.05228 14 9.5 13.5523 9.5 13V9H13.5C14.0523 9 14.5 8.55228 14.5 8C14.5 7.44772 14.0523 7 13.5 7H9.5V3C9.5 2.44772 9.05228 2 8.5 2Z"
                    fill="currentColor"
                  />
                </svg>
                <span>Add your request</span>
              </Button>
            </Link>
          </div>
          </section>
        </article>

        <div className="gap-4 flex flex-col sm:flex-row p-4 justify-between border-b border-solid border-b-slate-200">
          {" "}
          <Link to="/">
            <Button variant={ButtonVariant.Secondary}>
              <svg
                width="8"
                height="14"
                viewBox="0 0 8 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.5 0.292893C7.89052 0.683417 7.89052 1.31658 7.5 1.70711L3.20711 7L7.5 12.2929C7.89052 12.6834 7.89052 13.3166 7.5 13.7071C7.10948 14.0976 6.47631 14.0976 6.08579 13.7071L0.43934 8.06066C-0.146445 7.47487 -0.146448 6.52513 0.43934 5.93934L6.08579 0.292893C6.47631 -0.0976311 7.10948 -0.0976311 7.5 0.292893Z"
                  fill="currentColor"
                />
              </svg>
              <span>Go Back</span>
            </Button>
          </Link>
        </div>
      </DetailsPageWrapper>
      </>
    );
  }

  return <RecordDetails record={record}></RecordDetails>;
};
