import * as React from "react";
import { RichIcon } from "../../RichIcon";
import { marked } from "marked";
import DOMPurify from "dompurify";
import { Link, navigate } from "gatsby";
import { OutboundLink } from "gatsby-plugin-gtag";
import { PasskeySite } from "../context";
import { DetailsPageWrapper, Button, ButtonVariant } from "../../TailWind";
import StayInLoop from "../../StayInLoop";

export const RecordDetails: React.FC<{ record: PasskeySite }> = ({
  record,
}) => {
  return (
    <DetailsPageWrapper>
      <div className="gap-4 flex flex-col sm:flex-row p-4 justify-between border-b border-solid border-b-slate-200">
        {" "}
        <Link to="/">
          <Button variant={ButtonVariant.Secondary}>
            <svg
              width="8"
              height="14"
              viewBox="0 0 8 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.5 0.292893C7.89052 0.683417 7.89052 1.31658 7.5 1.70711L3.20711 7L7.5 12.2929C7.89052 12.6834 7.89052 13.3166 7.5 13.7071C7.10948 14.0976 6.47631 14.0976 6.08579 13.7071L0.43934 8.06066C-0.146445 7.47487 -0.146448 6.52513 0.43934 5.93934L6.08579 0.292893C6.47631 -0.0976311 7.10948 -0.0976311 7.5 0.292893Z"
                fill="currentColor"
              />
            </svg>

            <span>Go Back</span>
          </Button>
        </Link>
      </div>
      <article className="gap-4 flex flex-col p-12 items-center">
        <div className="flex justify-center shadow-xl rounded-2xl overflow-hidden ">
          <RichIcon record={record} size={180} height={20} width={20} />
        </div>

        <header className="items-center flex flex-col w-full gap-1">
          <h1 className="text-2xl font-bold text-left">{record.name}</h1>

          <a
            href={record.domain_full ? record.domain_full : ""}
            className="hover:underline underline-offset-4 font-normal text-left text-slate-500 text-sm"
            target="_blank"
          >
            {record.domain}
          </a>
          <div className="flex flex-col gap-2 py-2">
            {record.passkey_signin ? (
              <abbr
                className="inline-flex justify-center items-center text-center px-2 py-1 text-xs font-medium  text-emerald-800 bg-emerald-100 rounded-full no-underline"
                title="Support for signing in with passkey"
              >
                Sign in with passkey supported
              </abbr>
            ) : (
              ""
            )}

            {record.passkey_mfa ? (
              <abbr
                className="inline-flex justify-center items-center text-center px-2 py-1 text-xs font-medium  text-amber-700 bg-amber-100 rounded-full no-underline"
                title="Support for passkey as multi-factor authentication method"
              >
                Passkey MFA supported
              </abbr>
            ) : (
              ""
            )}
          </div>
        </header>

        {record.setup_link ? (
          <section className="flex flex-col justify-start items-start gap-2 pb-4 border-b border-slate-200 w-full">
            <h4 className="text-md font-medium">Where to set up</h4>
            <p className="text-left font-normal text-sm">
              <a
                href={record.setup_link}
                target="_blank"
                className="text-gray-500 text-sm underline underline-offset-2 break-all"
              >
                {record.setup_link}
              </a>
            </p>
          </section>
        ) : (
          ""
        )}

        {record.documentation_link ? (
          <section className="flex flex-col justify-start items-start gap-2 pb-4 border-b border-slate-200  w-full">
            <h4 className="text-md font-medium">Additional information</h4>
            <p className="text-left font-normal text-sm">
              <a
                href={record.documentation_link}
                target="_blank"
                className="text-gray-500 text-sm underline underline-offset-2 break-all"
              >
                {record.documentation_link}
              </a>
            </p>
          </section>
        ) : (
          ""
        )}

        {record.category ? (
          <section className="flex flex-col justify-start items-start gap-2 pb-4 border-b border-slate-200 w-full">
            <h4 className="text-md font-medium">Category</h4>
            <p className="text-left text-gray-500 font-normal text-sm">
              {record.category}
            </p>
          </section>
        ) : (
          ""
        )}

        {record.created_at ? (
          <section className="flex flex-col justify-start items-start gap-2 pb-4 border-b border-slate-200 w-full">
            <h4 className="text-md font-medium">Date added</h4>
            <p className="text-left text-gray-500 font-normal text-sm">
            {new Date(record.created_at ?? "").toLocaleDateString("en-us", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}            </p>
          </section>
        ) : (
          ""
        )}

        {record.notes ? ( 
          <section className="flex flex-col justify-start items-start gap-2 pb-4 mb-4 w-full">
            <h4 className="text-md font-medium">Notes</h4>
            <div className="w-full text-left text-gray-500 font-normal text-sm noteswrap" dangerouslySetInnerHTML={{__html: parseMarkdown(record.notes /*TEST_MARKDOWN*/)}}/>
          </section>
        ) : (
          ""
        )}
      </article>
    </DetailsPageWrapper>
  );
};

function parseMarkdown(markdown: string) {
  return DOMPurify.sanitize(marked.parse(markdown));
}

const TEST_MARKDOWN = `


Click the setup link and sign in with your email address and password, then follow the steps to set up passwordless login using biometrics to create a passkey.  ( Link to setup: https://support.docusign.com/s/document-item?bundleId=jux1643235969954&topicId=dzo1651694930392.html )

Sign out, then sign back in with your email address and choose Log in Without Password to use your passkey.

# Title 1
## Title 2
### Title 3

I'm a placeholder I'm a placeholder I'm a placeholder I'm a placeholder I'm a placeholder I'm a placeholder I'm a placeholder I'm a placeholder I'm a placeholder I'm a placeholder I'm a placeholder I'm a placeholder 

**bold** text _italic_ _**text**_

---
- bullet one
- bullet two
---
1. ordered one
2. ordered two

---

1. First item
2. Second item
3. Third item
    - Indented item
    - Indented item
4. Fourth item

---
1. First item
2. Second item
3. Third item
    1. Indented item
    2. Indented item
4. Fourth item

---
- First item
- Second item
- Third item
    - Indented item
    - Indented item
- Fourth item

mail@mail.com

My favorite search engine is [Duck Duck Go](https://duckduckgo.com).

I love supporting the **[EFF](https://eff.org)**.
This is the *[Markdown Guide](https://www.markdownguide.org)*.
`