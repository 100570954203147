import React from "react";
import { ActionSection, ButtonVariant, Button } from "./TailWind";
import { OutboundLink } from "gatsby-plugin-gtag";

export const StayInLoop: React.FC = () => {
  return (
    <ActionSection
      title="Stay in the loop"
      description="Register to help test out passkeys in 1Password, or simply be the first to know when we take the next step toward the passwordless future."
    >
      <OutboundLink href="https://1password.com/newsletter/" target="_blank">
        <Button variant={ButtonVariant.SecondaryBold}>
          <span>Sign up now</span>
        </Button>
      </OutboundLink>
    </ActionSection>
  );
};

export default StayInLoop;
