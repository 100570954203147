import React, { useReducer } from "react";
import { ApplicationContext } from "./ApplicationContext";
import applicationReducer, {
  initialApplicationState,
} from "./ApplicationState";
import { DirectoryTab } from "./types";

export interface ApplicationProviderProps {
  children: React.ReactNode;
}

export const ApplicationProvider: React.FC<ApplicationProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(applicationReducer, {
    ...initialApplicationState,
  });

  const selectDirectoryTab = (tab: DirectoryTab) => {
    dispatch({ type: "SELECT_TAB", tab });
  };

  return (
    <ApplicationContext.Provider
      value={{ state, dispatch, selectDirectoryTab }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};
