import React, { useState, useEffect } from "react";
import { RichIcon } from "../RichIcon";
import { RequestedSite, useRequestedSites } from "./context";
import { RequestedSitesListItem } from "./RequestedSitesListItem";

const initialCount = 20;

export const RequestedSitesList: React.FC = () => {
  const {
    state: { sites, showAllSites },
    toggleShowAllSites,
  } = useRequestedSites();

  const [topSites, setTopSites] = useState<RequestedSite[]>([]);
  const [otherSites, setOtherSites] = useState<RequestedSite[]>([]);

  useEffect(() => {
    if (sites && sites.length) {
      setTopSites([...sites].splice(0, initialCount));
      setOtherSites([...sites].splice(initialCount));
    } else {
      setTopSites([]);
      setOtherSites([]);
    }
  }, []);

  useEffect(() => {
    if (sites && sites.length) {
      setTopSites([...sites].splice(0, initialCount));
      setOtherSites([...sites].splice(initialCount));
    } else {
      setTopSites([]);
      setOtherSites([]);
    }
  }, [sites]);

  return (
    <div className="flex flex-col ">
      {topSites.map((record) => {
        return <RequestedSitesListItem record={record} key={record.id} />;
      })}

      <div className="flex flex-row justify-between items-center mb-3">
        <hr className="border border-gray-not-in-palette-1 h-0 flex-1 mr-2" />
        {otherSites.length > 0 && (
          <>
            <div
              onClick={() => {
                toggleShowAllSites();
              }}
              className={
                showAllSites
                  ? "rounded-lg hover:cursor-pointer border border-white bg-blue-500 text-white px-3 py-2 text-sm"
                  : "rounded-lg hover:cursor-pointer border border-gray-300 bg-white text-blue-not-in-palette-1 px-3 py-2 shadow text-sm"
              }
            >
              {showAllSites
                ? `Show only top ${initialCount}`
                : "Show all votes"}
            </div>
            <hr className="border border-gray-not-in-palette-1 h-0 flex-1 ml-2" />
          </>
        )}
      </div>
      {showAllSites &&
        otherSites.map((record) => {
          return <RequestedSitesListItem record={record} key={record.id} />;
        })}
    </div>
  );
};
