import * as React from "react";
import { OutboundLink } from "gatsby-plugin-gtag";
import { MegaphoneIcon } from "@heroicons/react/24/outline";

export const Banner: React.FC = () => {
  return (
    <>
      {/* Banner */}
      <div className="md:rounded-full md:p-4 z-40 w-full absolute top-0 left-0">
        <div className="mx-auto bg-violet-600 shadow-xl md:rounded-lg lg:rounded-lg max-w-7xl py-2 px-3">
          <div className="flex flex-wrap items-center justify-between">
            <div className="flex w-0 flex-1 items-center mx-2">
              <span className="flex p-2">
                <MegaphoneIcon
                  className="h-6 w-6 text-white"
                  aria-hidden="true"
                />
              </span>
              <p className="ml-3 truncate font-medium text-white">
                <span className="md:hidden">1Password acquires Passage!</span>
                <span className="hidden md:inline">
                  1Password acquires Passage to help accelerate passwordless
                  adoption
                </span>
              </p>
            </div>
            <div className="order-3 mt-2 w-full flex-shrink-0 sm:order-2 sm:mt-0 sm:w-auto">
              <OutboundLink
                href="https://blog.1password.com/1password-acquires-passage?utm_source=passkeys_directory&utm_medium=banner&utm_campaign=passage_acquisition"
                target="_blank"
                className="flex items-center justify-center rounded-lg border border-transparent bg-white px-4 py-2 text-sm font-medium text-violet-600 shadow-sm hover:bg-indigo-50"
              >
                Learn more
              </OutboundLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
