import { SupabaseClient } from "@supabase/supabase-js";
import { useState } from "react";
import { Database } from "../../../types/supabase";
import { SupabaseAuthUser, VisitorData } from "./types";

interface SupabaseAuthMethods {
  signIn: (visitor: VisitorData) => Promise<SupabaseAuthUser>;
  signUp: (visitor: VisitorData) => Promise<SupabaseAuthUser>;
}

export const useSupabaseAuth = (
  client: SupabaseClient<Database> | undefined | null
): SupabaseAuthMethods => {
  const _getEmail = (visitor: VisitorData): string => {
    return `${visitor.visitorId}@passage.id`;
  };

  const _getPassword = (visitor: VisitorData): string => {
    return visitor.visitorId;
  };

  const _getEmailPassword = (
    visitor: VisitorData
  ): { email: string; password: string } => {
    return {
      email: _getEmail(visitor),
      password: _getPassword(visitor),
    };
  };

  const signIn = async (visitor: VisitorData): Promise<SupabaseAuthUser> => {
    if (!client) {
      throw new Error("invalid connection");
    }

    const { email, password } = _getEmailPassword(visitor);
    const { data, error } = await client.auth.signInWithPassword({
      email,
      password,
    });
    if (error) {
      throw error;
    }

    if (!data || !data.user || !data.user.id || !data.user?.email) {
      throw new Error("invalid user data");
    }

    return { ...data, id: data.user.id, email: data.user.email };
  };

  const signUp = async (visitor: VisitorData): Promise<SupabaseAuthUser> => {
    if (!client) {
      throw new Error("invalid connection");
    }

    const { email, password } = _getEmailPassword(visitor);

    const { data, error } = await client.auth.signUp({ email, password });
    if (error) {
      throw error;
    }

    if (!data || !data.user || !data.user.id || !data.user?.email) {
      throw new Error("invalid user data");
    }

    return { ...data, id: data.user.id, email: data.user.email };
  };

  return {
    signIn,
    signUp,
  };
};
