import React, { useEffect, useReducer } from "react";
import { Database } from "../../../types/supabase";
import { RequestedSitesContext } from "./RequestedSitesContext";
import { useSupabase } from "../../Supabase";
import {
  requestedSitesReducer,
  initialRequestedSitesState,
} from "./RequestedSitesState";
import { RequestedSite, REQUESTED_SITES_ORDER_BY } from "./types";

export interface RequestedSitesProviderProps {
  children: React.ReactNode;
}

export const RequestedSitesProvider: React.FC<RequestedSitesProviderProps> = ({
  children,
}) => {
  const { client } = useSupabase();

  const [state, dispatch] = useReducer(requestedSitesReducer, {
    ...initialRequestedSitesState,
  });

  const loadAll = async (): Promise<void> => {
    dispatch({ type: "FETCH_REQUESTED_SITES" });
    if (client) {
      const { data, error: dbError } = await client
        .from<
          "requested_sites",
          Database["public"]["Views"]["requested_sites"]
        >("requested_sites")
        .select("*")
        .eq("approved", true);
      if (dbError) {
        dispatch({
          type: "FETCH_REQUESTED_SITES_ERROR",
          dbError,
          error: new Error("Data access error."),
        });
      } else {
        dispatch({
          type: "FETCH_REQUESTED_SITES_SUCCESS",
          sites: data || [],
        });
      }
    } else {
      dispatch({
        type: "FETCH_REQUESTED_SITES_ERROR",
        error: new Error("DB Client is undefined"),
      });
    }
  };

  const requestSite = async (
    site: Pick<RequestedSite, "name" | "domain" | "category">
  ) => {
    dispatch({ type: "REQUEST_SITE" });
    if (client) {
      const { error: dbError } = await client
        .from<"sites", Database["public"]["Tables"]["sites"]>("sites")
        .insert({
          ...site,
          passkey_mfa: false,
          passkey_signin: false,
          approved: false,
          hidden: false,
        });

      if (dbError) {
        dispatch({
          type: "REQUEST_SITE_ERROR",
          error: new Error(dbError.message),
          dbError,
        });
        throw new Error(dbError.message);
      } else {
        dispatch({ type: "REQUEST_SITE_SUCCESS" });
      }
    } else {
      dispatch({
        type: "REQUEST_SITE_ERROR",
        error: new Error("DB Client is undefined"),
      });
      throw new Error("An error occured.");
    }
  };

  const clearSearchTerm = (): void => {
    dispatch({ type: "CLEAR_SEARCH_TERM" });
  };

  const setSearchTerm = (searchTerm: string | undefined | null): void => {
    dispatch({ type: "SET_SEARCH_TERM", searchTerm });
  };

  const refreshSites = async (): Promise<void> => {
    dispatch({ type: "REFRESH_REQUESTED_SITES" });
    if (client) {
      const { data, error: dbError } = await client
        .from<
          "requested_sites",
          Database["public"]["Views"]["requested_sites"]
        >("requested_sites")
        .select("*")
        .eq("approved", true);
      if (dbError) {
        dispatch({
          type: "REFRESH_REQUESTED_SITES_ERROR",
          dbError,
          error: new Error("Data access error."),
        });
      } else {
        dispatch({
          type: "REFRESH_REQUESTED_SITES_SUCCESS",
          sites: data || [],
        });
      }
    } else {
      dispatch({
        type: "REFRESH_REQUESTED_SITES_ERROR",
        error: new Error("DB Client is undefined"),
      });
    }
  };

  const toggleShowAllSites = (): void => {
    dispatch({ type: "TOGGLE_SHOW_ALL_SITES" });
  };
  useEffect(() => {
    if (!state.allSites.length) {
      loadAll();
    }
  }, []);

  const setOrderBy = (orderBy: REQUESTED_SITES_ORDER_BY): void => {
    dispatch({ type: "SET_ORDER_BY", orderBy });
  };
  return (
    <RequestedSitesContext.Provider
      value={{
        clearSearchTerm,
        client,
        dispatch,
        loadAll,
        setSearchTerm,
        state,
        requestSite,
        refreshSites,
        toggleShowAllSites,
        setOrderBy,
      }}
    >
      {children}
    </RequestedSitesContext.Provider>
  );
};
