import React from "react";
import { UserProfile, UserAction, UserStateProps } from "./types";

export const noopUserDispatch: React.Dispatch<UserAction> = () => {
  return null;
};

export const initialUserState: UserStateProps = {
  debug: false,
  error: undefined,
  loading: false,
  user: undefined,
  authUser: undefined,
  votes: [],
  visitor: undefined,
};

export const userReducer = (state: UserStateProps, action: UserAction) => {
  if (state.debug) {
    console.log("DISPATCH[userReducer]: action:", action);
  }

  switch (action.type) {
    case "FINGERPRINT_LOADING":
      return {
        ...state,
        error: undefined,
        loading: true,
        user: undefined,
        authUser: undefined,
        votes: [],
        visitor: undefined,
      };
    case "FINGERPRINT_LOAD_SUCCESS":
      return {
        ...state,
        error: undefined,
        loading: false,
        visitor: action.visitor,
      };
    case "FINGERPRINT_LOAD_ERROR":
      return {
        ...state,
        error: action.error,
        loading: false,
        visitor: undefined,
      };
    case "AUTH_OR_CREATE_USER":
      return {
        ...state,
        user: undefined,
        authUser: undefined,
        error: undefined,
        loading: true,
      };
    case "AUTH_OR_CREATE_USER_SUCCESS":
      return {
        ...state,
        authUser: action.authUser,
        loading: false,
        error: undefined,
      };
    case "AUTH_OR_CREATE_USER_ERROR":
      return {
        ...state,
        authUser: undefined,
        loading: false,
        error: action.error,
      };
    case "CREATE_USER_PROFILE":
      return {
        ...state,
        user: undefined,
        loading: true,
        error: undefined,
      };
    case "CREATE_USER_PROFILE_SUCCESS":
      return {
        ...state,
        user: action.user,
        loading: false,
        error: undefined,
      };
    case "CREATE_USER_PROFILE_ERROR":
      return {
        ...state,
        user: undefined,
        loading: false,
        error: action.error,
      };
    case "LOAD_USER_PROFILE":
      return {
        ...state,
        user: undefined,
        loading: true,
        error: undefined,
      };
    case "LOAD_USER_PROFILE_SUCCESS":
      return {
        ...state,
        user: action.user,
        error: undefined,
        loading: false,
      };
    case "LOAD_USER_PROFILE_ERROR":
      return {
        ...state,
        user: undefined,
        error: action.error,
        loading: false,
      };
    case "LOAD_VOTES":
      return {
        ...state,
        loading: true,
        error: undefined,
        votes: [],
      };
    case "LOAD_VOTES_SUCCESS":
      return {
        ...state,
        loading: false,
        error: undefined,
        votes: action.votes,
      };
    case "LOAD_VOTES_ERROR":
      return {
        ...state,
        error: action.error,
        votes: [],
      };
    case "CAST_VOTE":
      return {
        ...state,
        error: undefined,
      };
    case "CAST_VOTE_ERROR":
      return {
        ...state,
        error: action.error,
      };
    case "CAST_VOTE_SUCCESS":
      return {
        ...state,
        loading: true,
        error: undefined,
        votes: [...state.votes, action.vote],
      };
    case "UNVOTE":
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case "UNVOTE_SUCCESS": {
      return {
        ...state,
        loading: false,
        error: undefined,
        votes: [...state.votes.filter((v) => v.id !== action.vote.id)],
      };
    }
    case "UNVOTE_ERROR":
      return { ...state, loading: false, error: action.error };
    default:
      return {
        ...state,
      };
  }
};

export default userReducer;
