import {
  PasskeySite,
  PasskeySitesAction,
  PasskeySitesStateProps,
  PASSKEY_SITES_FILTER_BY,
  PASSKEY_SITES_ORDER_BY,
} from "./types";

export const noopPasskeySitesDispatch: React.Dispatch<
  PasskeySitesAction
> = () => {
  return null;
};

export const initialPasskeySitesState: PasskeySitesStateProps = {
  debug: false,
  error: undefined,
  dbError: undefined,
  loading: false,
  allPasskeySites: [],
  passkeySites: [],
  searchTerm: undefined,
  orderBy: PASSKEY_SITES_ORDER_BY.NAME,
  filterBy: PASSKEY_SITES_FILTER_BY.ALL,
};

const compareName = (a: PasskeySite, b: PasskeySite) => {
  if (a.name && b.name) {
    return a.name.localeCompare(b.name);
  }
  return 0;
};

const compareNameDesc = (a: PasskeySite, b: PasskeySite) => {
  if (a.name && b.name) {
    return b.name.localeCompare(a.name);
  }
  return 0;
};

const compareCategory = (a: PasskeySite, b: PasskeySite) => {
  if (a.category && b.category) {
    return a.category.localeCompare(b.category);
  }
  return 0;
};

const compareCategoryDesc = (a: PasskeySite, b: PasskeySite) => {
  if (a.category && b.category) {
    return b.category.localeCompare(a.category);
  }
  return 0;
};

const compareDateRecent = (a: PasskeySite, b: PasskeySite) => {
  if (a.created_at && b.created_at) {
    return b.created_at.localeCompare(a.created_at);
  }
  return 0;
};

const compareDateOrder = (a: PasskeySite, b: PasskeySite) => {
  if (a.created_at && b.created_at) {
    return a.created_at.localeCompare(b.created_at);
  }
  return 0;
};


const filterAndOrderSites = (
  sites: PasskeySite[],
  filters: {
    searchTerm: string | null | undefined;
    orderBy: PASSKEY_SITES_ORDER_BY;
    filterBy: PASSKEY_SITES_FILTER_BY;
  }
): PasskeySite[] => {
  let newSites: PasskeySite[] = [];
  newSites = [
    ...(filters.searchTerm
      ? sites.filter((site) =>
          site.name
            ?.toLowerCase()
            .includes((filters.searchTerm || "").toLowerCase())
        )
      : sites),
  ];
  if (filters.filterBy === PASSKEY_SITES_FILTER_BY.MFA) {
    newSites = [
      ...newSites.filter((site) => site.passkey_mfa && site.passkey_signin),
    ];
  } else if (filters.filterBy === PASSKEY_SITES_FILTER_BY.SIGN_IN) {
    newSites = [
      ...newSites.filter((site) => site.passkey_signin && !site.passkey_mfa),
    ];
  } else {
    newSites = [...newSites.filter((site) => site.passkey_signin)];
  }

  switch (filters.orderBy) {
    case PASSKEY_SITES_ORDER_BY.CATEGORY:
      return newSites.sort(compareCategory);
    case PASSKEY_SITES_ORDER_BY.CATEGORY_DESC:
      return newSites.sort(compareCategoryDesc);
    case PASSKEY_SITES_ORDER_BY.NAME_DESC:
      return newSites.sort(compareNameDesc);
    case PASSKEY_SITES_ORDER_BY.DATE_RECENT:
      return newSites.sort(compareDateRecent);
    case PASSKEY_SITES_ORDER_BY.DATE_ORDER:
      return newSites.sort(compareDateOrder); 
    case PASSKEY_SITES_ORDER_BY.NAME:
    default:
      return newSites.sort(compareName);
  }
};

const filterSitesBySearchTerm = (
  sites: PasskeySite[],
  searchTerm: string | undefined | null
): PasskeySite[] => {
  if (searchTerm) {
    // const regex = new RegExp(`/*${searchTerm}*`);
    return sites.filter((record) =>
      record.name?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }

  return sites;
};

export const passkeySitesReducer = (
  state: PasskeySitesStateProps,
  action: PasskeySitesAction
) => {
  if (state.debug) {
    console.log("DISPATCH: action:", action);
  }

  switch (action.type) {
    case "FETCH_PASSKEY_SITES":
      return {
        ...state,
        allPasskeySites: [],
        error: undefined,
        dbError: undefined,
        loading: true,
        passkeySites: [],
      };
    case "FETCH_PASSKEY_SITES_SUCCESS": {
      return {
        ...state,
        allPasskeySites: action.passkeySites,
        dbError: undefined,
        error: undefined,
        loading: false,
        passkeySites: [
          ...filterAndOrderSites(action.passkeySites, {
            searchTerm: state.searchTerm,
            orderBy: state.orderBy,
            filterBy: state.filterBy,
          }),
        ],
      };
    }
    case "FETCH_PASSKEY_SITES_ERROR":
      return {
        ...state,
        allPasskeySites: [],
        ddbError: action.dbError,
        error: action.error,
        loading: false,
        passkeySites: [],
      };
    case "CLEAR_SEARCH_TERM": {
      return {
        ...state,
        searchTerm: undefined,
        passkeySites: [
          ...filterAndOrderSites(state.allPasskeySites, {
            searchTerm: undefined,
            orderBy: state.orderBy,
            filterBy: state.filterBy,
          }),
        ],
      };
    }
    case "SET_SEARCH_TERM": {
      const newSites = filterSitesBySearchTerm(
        state.allPasskeySites,
        action.searchTerm
      );
      return {
        ...state,
        searchTerm: action.searchTerm,
        passkeySites: [
          ...filterAndOrderSites(state.allPasskeySites, {
            searchTerm: action.searchTerm,
            orderBy: state.orderBy,
            filterBy: state.filterBy,
          }),
        ],
      };
    }
    case "SET_FILTER_BY": {
      return {
        ...state,
        filterBy: action.filterBy,
        passkeySites: [
          ...filterAndOrderSites(state.allPasskeySites, {
            searchTerm: state.searchTerm,
            orderBy: state.orderBy,
            filterBy: action.filterBy,
          }),
        ],
      };
    }
    case "SET_ORDER_BY": {
      return {
        ...state,
        orderBy: action.orderBy,
        passkeySites: [
          ...filterAndOrderSites(state.allPasskeySites, {
            searchTerm: state.searchTerm,
            orderBy: action.orderBy,
            filterBy: state.filterBy,
          }),
        ],
      };
    }
    default:
      return {
        ...state,
      };
  }
};
