import * as React from "react";
import { DirectoryRow } from "./DirectoryRow";
import { usePasskeySites } from "../context";

export const DirectoryTable: React.FC = () => {
  const {
    state: { passkeySites: sites, allPasskeySites: allSites },
  } = usePasskeySites();

  if (!allSites.length) {
    return (
      <table className="bg-zinc-50 w-full max-w-5xl rounded-xl mx-auto px-4 directory-table"></table>
    );
  }
  return (
    <table className="bg-zinc-50 w-full max-w-5xl rounded-xl mx-auto px-4 directory-table">
      <thead className=" border-b border-white-200">
        <tr>
          <th
            scope="col"
            className="pl-4 md:px-4 py-3 text-left text-xs font-medium text-zinc-500 uppercase tracking-wider"
          >
            Name
          </th>
          <th
            scope="col"
            className="px-4 py-3 text-center sm:text-left text-xs font-medium text-zinc-500 uppercase tracking-wider"
          >
            Supported
          </th>
          <th
            scope="col"
            className="hidden sm:table-cell pl-4 md:px-4 py-3 text-left text-xs font-medium text-zinc-500 uppercase tracking-wider"
          >
            Category
          </th>
          <th
            scope="col"
            className="pl-4 md:px-4 py-3 text-left text-xs font-medium text-zinc-500 uppercase tracking-wider"
          ></th>
        </tr>
      </thead>
      <tbody className="w-full bg-white shadow-sm">
        {sites.map((record) => {
          return <DirectoryRow record={record} key={record.name} />;
        })}
      </tbody>
      <tfoot>
        <tr>
          <td
            colSpan={5}
            className="w-full py-2 text-sm font-normal text-zinc-500 align-middle justify-center text-center "
          >
            Displaying {sites.length} results
          </td>
        </tr>
      </tfoot>
    </table>
  );
};
