import {
  RequestedSite,
  RequestedSitesAction,
  RequestedSitesStateProps,
  REQUESTED_SITES_ORDER_BY,
} from "./types";

export const noopRequestedSitesDispatch: React.Dispatch<
  RequestedSitesAction
> = () => {
  return null;
};

const compareName = (a: RequestedSite, b: RequestedSite) => {
  if (a.name && b.name) {
    return a.name.localeCompare(b.name);
  }
  return 0;
};

const compareNameDesc = (a: RequestedSite, b: RequestedSite) => {
  if (a.name && b.name) {
    return b.name.localeCompare(a.name);
  }
  return 0;
};

const compareCategory = (a: RequestedSite, b: RequestedSite) => {
  if (a.category && b.category) {
    return a.category.localeCompare(b.category);
  }
  return 0;
};

const compareCategoryDesc = (a: RequestedSite, b: RequestedSite) => {
  if (a.category && b.category) {
    return b.category.localeCompare(a.category);
  }
  return 0;
};

const compareDateRecent = (a: RequestedSite, b: RequestedSite) => {
  if (a.created_at && b.created_at) {
    return b.created_at.localeCompare(a.created_at);
  }
  return 0;
};

const compareDateOrder = (a: RequestedSite, b: RequestedSite) => {
  if (a.created_at && b.created_at) {
    return a.created_at.localeCompare(b.created_at);
  }
  return 0;
};

const compareVotes = (a: RequestedSite, b: RequestedSite) => {
  if (a.count === b.count) {
    return 0;
  }
  return (b.count || 0) > (a.count || 0) ? 1 : -1;
};

const filterAndOrderSites = (
  sites: RequestedSite[],
  filters: {
    searchTerm: string | null | undefined;
    orderBy: REQUESTED_SITES_ORDER_BY;
  }
): RequestedSite[] => {
  let newSites: RequestedSite[] = [];
  newSites = [
    ...(filters.searchTerm
      ? sites.filter((site) =>
          site.name
            ?.toLowerCase()
            .includes((filters.searchTerm || "").toLowerCase())
        )
      : sites),
  ];

  switch (filters.orderBy) {
    case REQUESTED_SITES_ORDER_BY.CATEGORY:
      return newSites.sort(compareCategory);
    case REQUESTED_SITES_ORDER_BY.CATEGORY_DESC:
      return newSites.sort(compareCategoryDesc);
    case REQUESTED_SITES_ORDER_BY.NAME_DESC:
      return newSites.sort(compareNameDesc);
    case REQUESTED_SITES_ORDER_BY.NAME:
      return newSites.sort(compareName);
    case REQUESTED_SITES_ORDER_BY.DATE_RECENT:
      return newSites.sort(compareDateRecent);
    case REQUESTED_SITES_ORDER_BY.DATE_ORDER:
      return newSites.sort(compareDateOrder);
    case REQUESTED_SITES_ORDER_BY.VOTES:
    default:
      return newSites.sort(compareVotes);
  }
};

export const initialRequestedSitesState: RequestedSitesStateProps = {
  debug: false,
  error: undefined,
  dbError: undefined,
  loading: false,
  allSites: [],
  sites: [],
  searchTerm: undefined,
  orderBy: REQUESTED_SITES_ORDER_BY.VOTES,
  showAllSites: false,
};

export const requestedSitesReducer = (
  state: RequestedSitesStateProps,
  action: RequestedSitesAction
) => {
  if (state.debug) {
    console.log("DISPATCH[requestedSites]: action:", action);
  }

  switch (action.type) {
    case "FETCH_REQUESTED_SITES":
      return {
        ...state,
        allSites: [],
        error: undefined,
        dbError: undefined,
        loading: true,
        sites: [],
      };
    case "FETCH_REQUESTED_SITES_SUCCESS": {
      return {
        ...state,
        allSites: action.sites,
        dbError: undefined,
        error: undefined,
        loading: false,
        sites: [
          ...filterAndOrderSites(action.sites, {
            searchTerm: state.searchTerm,
            orderBy: state.orderBy,
          }),
        ],
      };
    }
    case "FETCH_REQUESTED_SITES_ERROR":
      return {
        ...state,
        allsites: [],
        ddbError: action.dbError,
        error: action.error,
        loading: false,
        sites: [],
      };
    case "REFRESH_REQUESTED_SITES":
      return {
        ...state,
        loading: true,
        dbError: undefined,
        error: undefined,
      };
    case "REFRESH_REQUESTED_SITES_SUCCESS": {
      return {
        ...state,
        loading: false,
        dbError: undefined,
        error: undefined,
        allSites: action.sites,
        sites: [
          ...filterAndOrderSites(action.sites, {
            searchTerm: state.searchTerm,
            orderBy: state.orderBy,
          }),
        ],
      };
    }
    case "REFRESH_REQUESTED_SITES_ERROR":
      return {
        ...state,
        loading: false,
        error: action.error,
        dbError: action.dbError,
      };
    case "CLEAR_SEARCH_TERM": {
      return {
        ...state,
        searchTerm: undefined,
        sites: [
          ...filterAndOrderSites(state.allSites, {
            searchTerm: undefined,
            orderBy: state.orderBy,
          }),
        ],
      };
    }
    case "SET_SEARCH_TERM": {
      return {
        ...state,
        searchTerm: action.searchTerm,
        sites: [
          ...filterAndOrderSites(state.allSites, {
            searchTerm: action.searchTerm,
            orderBy: state.orderBy,
          }),
        ],
      };
    }
    case "REQUEST_SITE":
      return {
        ...state,
        loading: true,
      };
    case "REQUEST_SITE_ERROR":
      return {
        ...state,
        loading: false,
        error: action.error,
        dbError: action.dbError,
      };
    case "REQUEST_SITE_SUCCESS":
      return {
        ...state,
        loading: false,
      };
    case "TOGGLE_SHOW_ALL_SITES":
      return {
        ...state,
        showAllSites: !state.showAllSites,
      };
    case "SET_ORDER_BY":
      return {
        ...state,
        orderBy: action.orderBy,
        sites: [
          ...filterAndOrderSites(state.allSites, {
            searchTerm: state.searchTerm,
            orderBy: action.orderBy,
          }),
        ],
      };
    default:
      return {
        ...state,
      };
  }
};
