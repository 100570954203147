import { createContext, useContext } from "react";
import { RequestedSite } from "../../RequestedSites";
import { UserProfile, UserContextProps, UserVote } from "./types";
import { initialUserState, noopUserDispatch } from "./UserState";

const noopPromise = async (): Promise<void> => {
  return;
};

const initialUserContext: UserContextProps = {
  client: undefined,
  state: initialUserState,
  dispatch: noopUserDispatch,
  loadUser: noopPromise,
  createUser: noopPromise,
  loadOrCreateUser: noopPromise,
  loadUserVotes: async (user: UserProfile) => {
    return;
  },
  castVote: async (user: UserProfile, site: RequestedSite) => {
    return {} as UserVote;
  },
  unvote: async (vote: UserVote): Promise<void> => {
    return;
  },
};

export const UserContext = createContext<UserContextProps>(initialUserContext);

export const useUser = (): UserContextProps => {
  return useContext(UserContext);
};

export default useUser;
