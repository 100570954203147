import * as React from "react";
import { MegaphoneIcon } from "@heroicons/react/24/outline";
import { OutboundLink } from "gatsby-plugin-gtag";

export const RootBanner: React.FC = () => {
  return (
    <>
      {/* Banner */}
      <div className="md:rounded-full md:p-4 z-40 w-full absolute top-0 left-0">
        <div className="mx-auto background-blue-not-in-palette shadow-xl md:rounded-2xl lg:rounded-2xl max-w-7xl py-2 px-3">
          <div className="flex flex-wrap items-center justify-between">
            <div className="flex w-0 flex-1 items-center mx-2 justify-center md:justify-start">
              <span className="flex hidden md:inline">
                <img
                  className="h-6 text-white"
                  aria-hidden="true"
                  src="/static/passage.svg"
                />
              </span>
              <p className="ml-3 text-white sm:text-center">
                <span className="lg:hidden" style={{ fontSize: "13.7143px" }}>
                  How to support passkeys in your app or website
                </span>
                <span
                  className="hidden lg:inline"
                  style={{ fontSize: "13.7143px" }}
                >
                  Want to support passkeys in your app or website? Passage by
                  1Password can help.
                </span>
              </p>
            </div>
            <div className="order-3 mt-2 w-full flex-shrink-0 font-medium sm:order-2 sm:mt-0 sm:w-auto">
              <OutboundLink
                href="https://passage.1password.com/?utm_source=passkey+directory&utm_medium=banner&utm_campaign=passkey-directory"
                target="_blank"
                className="flex items-center justify-center rounded-lg border border-transparent bg-white px-4 py-2 text-sm font-medium text-blue-700 shadow-sm hover:bg-indigo-50"
              >
                Get started
              </OutboundLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RootBanner;
