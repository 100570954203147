import React, { useState, useEffect } from "react";

interface Tab {
  id: string;
  title: string;
  header?: React.ReactNode;
  content: React.ReactNode;
  badge?: string;
}
interface TabsProps {
  tabs: Tab[];
  activeTabId?: string;
  onSelectTab?: (id: string) => void;
}

export enum TabClasses {
  TAB_ACTIVE = "inline-block p-4 text-gray-800 bg-white rounded-t-lg active flex-1 bg-white flex-1",
  TAB_INACTIVE = "inline-block p-4 rounded-t-lg hover:text-gray-600 hover:bg-gray-100 bg-gray-200 flex-1 ",
  TAB_HEADER_CONTAINER = "w-full min-h-fit md:flex-nowrap justify-between sticky top-0 shadow-xl rounded-b-2xl p-3  mb-8 flex flex-row z-50 border border-white bg-gradient-to-b from-white to-slate-100",
}

export const Tabs: React.FC<TabsProps> = ({
  tabs,
  activeTabId,
  onSelectTab,
}) => {
  const [activeTab, setActiveTab] = useState<Tab | undefined>(tabs[0]);

  const selectTab = (id: string) => {
    if (onSelectTab) {
      onSelectTab(id);
    } else {
      setActiveTab(
        tabs.find((t) => {
          t.id === id;
        })
      );
    }
  };

  useEffect(() => {
    if (activeTabId) {
      const t = tabs.find((t) => t.id === activeTabId);
      setActiveTab(t);
    }
  }, [activeTabId, tabs]);

  return (
    <>
      <div className="container w-full sticky top-0">
        <div className="flex flex-auto ">
          <ul className="flex flex-row basis-full w-full flex-wrap items-center justify-center text-sm font-medium text-center text-gray-500 min-h-full tab">
            {tabs.map((tab) => {
              return (
                <li
                  key={tab.id}
                  className={
                    activeTab?.id === tab.id
                      ? "flex flex-1 justify-center h-full p-4 text-sm rounded-t-2-5xl bg-white text-gray-800 active"
                      : "flex flex-1 justify-center h-full p-4 text-sm rounded-t-2-5xl bg-gray-200  hover:text-gray-600 hover:bg-gray-100"
                  }
                >
                  <a
                    href="#"
                    onClick={(e) => {
                      selectTab(tab.id);
                      e.preventDefault();
                    }}
                    className="flex justify-center items-center flex-row w-full"
                  >
                    {tab.title}
                    {tab.badge && (
                      <span className="background-gold-no-in-palette-1 rounded-2xl p-1 px-2 font-bold text-brown-not-in-palette text-xss ml-2">
                        {tab.badge}
                      </span>
                    )}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
        {activeTab && activeTab.header && (
          <div
            className="w-full  justify-between sticky top-0 shadow-xl rounded-b-2xl p-3 mb-8 flex flex-col z-50 border border-white bg-gradient-to-b from-white to-slate-100"
            // className="w-full sticky top-0 rounded-b-2xl border border-white shadow-xl p-3 bg-gradient-to-b from-white to-slate-100"
            style={{ marginTop: "-1px" }}
          >
            {activeTab.header}
          </div>
        )}
      </div>
      <div>{activeTab && activeTab.content}</div>
    </>
  );
};
