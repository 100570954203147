import { createContext, useContext } from "react";
import {
  PasskeySitesContextProps,
  PASSKEY_SITES_FILTER_BY,
  PASSKEY_SITES_ORDER_BY,
} from "./types";
import {
  initialPasskeySitesState,
  noopPasskeySitesDispatch,
} from "./PasskeySitesState";

const initialPasskeySitesContext: PasskeySitesContextProps = {
  client: undefined,
  state: initialPasskeySitesState,
  dispatch: noopPasskeySitesDispatch,
  loadAll: async () => {
    return;
  },
  clearSearchTerm: () => {
    return;
  },
  setSearchTerm: (searchTerm: string | undefined | null) => {
    return;
  },
  setOrderBy: (orderBy: PASSKEY_SITES_ORDER_BY): void => {
    return;
  },
  setFilterBy: (filterBy: PASSKEY_SITES_FILTER_BY): void => {
    return;
  },
};

export const PasskeySitesContext = createContext<PasskeySitesContextProps>(
  initialPasskeySitesContext
);

export const usePasskeySites = (): PasskeySitesContextProps => {
  return useContext(PasskeySitesContext);
};

export default usePasskeySites;
