import React from "react";

// interface ButtonStyles {
//   primary: string;
//   secondary: string;
//   secondaryBold: string;
// }

// interface ButtonComponents {
//   styles: ButtonStyles;
// }

// const styles: ButtonStyles = {
//   primary:
//     "ml-2 sm:ml bg-gray-300 text-white gap-3 font-bold py-2 px-4 rounded-lg flex items-center cursor-pointer flex-nowrap btn-primary",
//   secondary:
//     "w-full sm:ml bg-white text-gray-900 gap-3 font-normal py-2 px-4 rounded-lg flex justify-center sm:justify-start items-center cursor-pointer light-gray-border btn-secondary",
//   secondaryBold:
//     "w-full sm:ml bg-white text-gray-900 gap-3 font-bold py-2 px-4 rounded-lg flex justify-center sm:justify-start items-center cursor-pointer light-gray-border btn-secondary",
// };

// export const Buttons: ButtonComponents = {
//   styles,
// };

// export enum LinkButtonVariant {
//   "Primary" = "primary",
//   "Secondary" = "secondary",
//   "SecondaryBold" = "secondary-bold",
// }
// export interface LinkButtonProps {
//   children: React.ReactNode;
//   variant: LinkButtonVariant;
// }
// export const LinkButton: React.FC<LinkButtonProps> = ({
//   children,
//   variant,
// }) => {
//   return (
//     <button
//       className={
//         variant === LinkButtonVariant.Primary
//           ? "ml-2 sm:ml bg-gray-300 text-white gap-3 font-bold py-2 px-4 rounded-lg flex items-center cursor-pointer flex-nowrap btn-primary"
//           : variant === LinkButtonVariant.Secondary
//           ? "w-full sm:ml bg-white text-gray-900 gap-3 font-normal py-2 px-4 rounded-lg flex justify-center sm:justify-start items-center cursor-pointer light-gray-border btn-secondary"
//           : "w-full sm:ml bg-white text-gray-900 gap-3 font-bold py-2 px-4 rounded-lg flex justify-center sm:justify-start items-center cursor-pointer light-gray-border btn-secondary"
//       }
//     >
//       {children}
//     </button>
//   );
// };

export enum ButtonVariant {
  "Primary" = "primary",
  "Secondary" = "secondary",
  "SecondaryBold" = "secondary-bold",
}

export interface ButtonProps {
  children: React.ReactNode;
  variant: ButtonVariant;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const Button: React.FC<ButtonProps> = ({
  children,
  variant,
  onClick,
}) => {
  if (onClick) {
    return (
      <button
        className={
          variant === ButtonVariant.Primary
            ? "ml-2 sm:ml bg-gray-300 text-white gap-3 font-bold py-2 px-4 rounded-lg flex items-center cursor-pointer flex-nowra hover:border-blue-100 btn-primary"
            : variant === ButtonVariant.Secondary
            ? "w-full sm:ml bg-white text-gray-900 hover:text-blue-900 gap-3 font-normal py-2 px-4 rounded-lg flex justify-center sm:justify-start items-center cursor-pointer light-gray-border hover:border-blue-100 btn-secondary"
            : "w-full sm:ml bg-white text-gray-900 hover:text-blue-900 gap-3 font-bold py-2 px-4 rounded-lg flex justify-center sm:justify-start items-center cursor-pointer light-gray-border hover:border-blue-100 btn-secondary"
        }
        onClick={onClick}
      >
        {children}
      </button>
    );
  }

  return (
    <button
      className={
        variant === ButtonVariant.Primary
          ? "ml-2 sm:ml bg-gray-300 text-white gap-3 font-bold py-2 px-4 rounded-lg flex items-center cursor-pointer flex-nowrap btn-primary"
          : variant === ButtonVariant.Secondary
          ? "w-full sm:ml bg-white text-gray-900 hover:text-blue-900 gap-3 font-normal py-2 px-4 rounded-lg flex justify-center sm:justify-start items-center cursor-pointer light-gray-border hover:border-blue-100 btn-secondary"
          : "w-full sm:ml bg-white text-gray-900 hover:text-blue-900 gap-3 font-bold py-2 px-4 rounded-lg flex justify-center sm:justify-start items-center cursor-pointer light-gray-border hover:border-blue-100 btn-secondary"
      }
    >
      {children}
    </button>
  );
};
