import { PostgrestError, SupabaseClient } from "@supabase/supabase-js";
import { Database } from "../../../types/supabase";
import { RequestedSite } from "../../RequestedSites";
import { UserProfile, UserVote, VisitorData } from "./types";

export interface UserSQL {
  fetchUser: (visitor: VisitorData) => Promise<UserProfile | undefined>;
  insertUser: (visitor: VisitorData) => Promise<UserProfile | undefined>;
  insertVote: (
    user: UserProfile,
    site: RequestedSite
  ) => Promise<UserVote | undefined>;
  fetchOrInsertUser: (visitor: VisitorData) => Promise<UserProfile | undefined>;
  fetchVotes: (user: UserProfile) => Promise<UserVote[]>;
  deleteVote: (vote: UserVote) => Promise<void>;
}

export const useUserSql = (
  client: SupabaseClient<Database> | undefined | null
): UserSQL => {
  const fetchUser = async (
    visitor: VisitorData
  ): Promise<UserProfile | undefined> => {
    if (!client) {
      throw new Error("DB client undefined");
    }
    const { data, error: dbError } = await client
      .from<"users", Database["public"]["Tables"]["users"]>("users")
      .select("*")
      .eq("visitor_id", visitor.visitorId);

    if (dbError) {
      throw dbError;
    }
    return data && data.length ? data[0] : undefined;
  };

  const insertUser = async (
    visitor: VisitorData
  ): Promise<UserProfile | undefined> => {
    if (!client) {
      throw new Error("DB client undefined");
    }

    const { data, error: dbError } = await client
      .from<"users", Database["public"]["Tables"]["users"]>("users")
      .insert({ visitor_id: visitor.visitorId })
      .select();

    if (dbError) {
      throw dbError;
    }
    return data && data.length ? data[0] : undefined;
  };

  const fetchOrInsertUser = async (
    visitor: VisitorData
  ): Promise<UserProfile | undefined> => {
    const fetchedUser = await fetchUser(visitor);
    if (fetchedUser) {
      return fetchedUser;
    }
    const insertedUser = await insertUser(visitor);

    return insertedUser;
  };

  const insertVote = async (
    user: UserProfile,
    site: RequestedSite
  ): Promise<UserVote | undefined> => {
    if (!client) {
      throw new Error("DB client undefined");
    }

    if (!user.id || !site || !site.id) {
      throw new Error("invalid query data");
    }

    const { data: vote, error: dbError } = await client
      .from<"votes", Database["public"]["Tables"]["votes"]>("votes")
      .insert({ site_id: site.id, user_id: user.id })
      .select();
    if (dbError) {
      throw dbError;
    }
    return vote && vote.length > 0 ? vote[0] : undefined;
  };

  const deleteVote = async (vote: UserVote): Promise<void> => {
    if (!client) {
      throw new Error("DB Client undefined");
    }

    if (!vote.id) {
      throw new Error("Invalid vote");
    }

    const { error } = await client
      .from<"votes", Database["public"]["Tables"]["votes"]>("votes")
      .delete()
      .eq("id", vote.id);

    if (error) {
      throw error;
    }
  };

  const fetchVotes = async (user: UserProfile): Promise<UserVote[]> => {
    if (!client) {
      throw new Error("DB client undefined");
    }

    if (!user.id) {
      throw new Error("user undefined");
    }

    const { data: votes, error: dbError } = await client
      .from<"votes", Database["public"]["Tables"]["votes"]>("votes")
      .select("*")
      .eq("user_id", user.id);

    if (dbError) {
      throw dbError;
    }
    return votes || [];
  };

  return {
    fetchUser,
    insertUser,
    insertVote,
    fetchOrInsertUser,
    fetchVotes,
    deleteVote,
  };
};
