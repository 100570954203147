import React, { useEffect } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { REQUESTED_SITES_ORDER_BY, useRequestedSites } from "./context";

type SearchInputOnChange = (event: React.ChangeEvent<HTMLInputElement>) => void;

type SelectOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => void;

export const RequestedSitesFilter: React.FC = () => {
  const {
    setSearchTerm,
    setOrderBy,
    loadAll,
    state: { searchTerm, sites, allSites, orderBy },
  } = useRequestedSites();

  const handleSearchInputChange: SearchInputOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchTerm(event.target.value);
  };

  const handleOrderByChange: SelectOnChange = (event) => {
    setOrderBy(event.target.value as REQUESTED_SITES_ORDER_BY);
  };

  return (
    <div className="flex  h-full w-full gap-4 flex-col sm:flex-row">
      <div className="flex-auto">
        <label
          htmlFor="directory-search"
          className="mb-2 gap-3 text-sm font-medium text-gray-900 sr-only dark:text-gray-300"
        >
          Search
        </label>
        <div className="flex relative h-full w-full gap-4">
          <div className="flex absolute inset-y-0 left-0 items-center pl-1 pointer-events-none">
            <MagnifyingGlassIcon
              className="h-6 w-6 text-zinc-500 ml-2"
              aria-hidden="true"
            />
          </div>
          <input
            type="search"
            inputMode="search"
            id="directory-search"
            value={searchTerm || ""}
            onChange={handleSearchInputChange}
            placeholder="Search passkeys.directory"
            className="w-full block p-2 pl-10 text-sm text-gray-900 bg-gray-50 rounded-lg border cursor-text border-gray-300 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
      </div>
      <div className="flex flex-row items-center relative max-w-fit">
        <label
          htmlFor="directory-orderby"
          className="text-gray-900 text-sm block mr-2"
        >
          Sort By
        </label>
        <select
          name="directory-orderby"
          id="directory-orderby"
          value={orderBy}
          onChange={handleOrderByChange}
          className="appearance-none text-gray-500 text-sm border border-gray-300 px-2 rounded-lg p-2 bg-gray-50 max-w-fit truncate pr-6"
        >
          <option value={REQUESTED_SITES_ORDER_BY.VOTES}>
            {REQUESTED_SITES_ORDER_BY.VOTES}
          </option>
          <option value={REQUESTED_SITES_ORDER_BY.NAME}>
            {REQUESTED_SITES_ORDER_BY.NAME}
          </option>
          <option value={REQUESTED_SITES_ORDER_BY.NAME_DESC}>
            {REQUESTED_SITES_ORDER_BY.NAME_DESC}
          </option>
          <option value={REQUESTED_SITES_ORDER_BY.CATEGORY}>
            {REQUESTED_SITES_ORDER_BY.CATEGORY}
          </option>
          <option value={REQUESTED_SITES_ORDER_BY.CATEGORY_DESC}>
            {REQUESTED_SITES_ORDER_BY.CATEGORY_DESC}
          </option>
          <option value={REQUESTED_SITES_ORDER_BY.DATE_RECENT}>
            {REQUESTED_SITES_ORDER_BY.DATE_RECENT}
          </option>
          <option value={REQUESTED_SITES_ORDER_BY.DATE_ORDER}>
            {REQUESTED_SITES_ORDER_BY.DATE_ORDER}
          </option>
        </select>
      </div>
    </div>
  );
};
