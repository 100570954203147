import { createContext, useContext } from "react";
import { ApplicationContextProps, DirectoryTab } from "./types";
import {
  initialApplicationState,
  noopApplicationDispatch,
} from "./ApplicationState";

const initialApplicationContext: ApplicationContextProps = {
  state: initialApplicationState,
  dispatch: noopApplicationDispatch,
  selectDirectoryTab: (tab: DirectoryTab) => {
    return;
  },
};

export const ApplicationContext = createContext<ApplicationContextProps>(
  initialApplicationContext
);

export const useApplication = (): ApplicationContextProps => {
  return useContext(ApplicationContext);
};

export default useApplication;
