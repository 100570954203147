import React from "react";

export interface PageSectionProps {
  children: React.ReactNode;
}

export const PageSection: React.FC<PageSectionProps> = ({ children }) => {
  return <div className="container px-4 sm:px-0 mb-8">{children}</div>;
};

export default PageSection;
