import React, { useEffect } from "react";
import { RichIcon } from "../RichIcon";
import { Link } from "gatsby";
import { directoryRecordKey } from "../../utils/utils";
import { ArrowRightCircleIcon } from "@heroicons/react/24/outline";
import { RequestedSite } from "./context";
import { UserVote, useUser } from "../User";
import { usePasskeySites } from "../PasskeySites";
import { VoteTile } from "./VoteTile";

export const RequestedSitesListItem: React.FC<{ record: RequestedSite }> = ({
  record,
}) => {
  const {
    state: { votes, user },
    castVote,
  } = useUser();
  const { loadAll } = usePasskeySites();

  const [vote, setVote] = React.useState<UserVote | undefined>(undefined);

  useEffect(() => {
    setVote(
      votes.find((v) => {
        return v.site_id === record.id;
      })
    );
  }, [votes]);

  return (
    <div className="flex flex-row items-center mb-2 bg-white rounded-xl border border-white shadow px-2 py-2.5">
      <div className="flex-1 whitespace-normal md:whitespace-nowrap">
        <div className="flex items-left flex-col sm:flex-row sm:items-center gap-2">
          <div className="flex-shrink-0 h-10 w-10 rounded-lg overflow-hidden bg-white  aspect-square">
            <RichIcon record={record} />
          </div>
          <div className="sm:ml-4 w-full">
            <div className="text-sm font-medium text-left text-gray-900 truncate max-w-[90%] xs:max-w-[100%]">
              {record.name}
            </div>
            <div className="whitespace-pre-line text-sm md:text-sm text-left text-gray-500 truncate max-w-[90%] xs:max-w-[100%]">
              <a
                href={record.domain ? `http://${record.domain}` : ""}
                target="_blank"
                className="whitespace-pre-line hover:underline underline-offset-4 truncate max-w-[80%] xs:max-w-[80%]"
              >
                {record.domain}
              </a>
              {/* <a href={`${record.domain_full}?utm_source=passkeys.directory`}>{record.domain}</a> */}
            </div>
          </div>
        </div>
      </div>
      <div className="flex-1 sm:table-cell  text-sm font-normal text-left text-gray-700 whitespace-normal md:whitespace-nowrap">
        {record.category || "Other"}
      </div>

      <div className="flex-1  text-sm font-normal  text-blue-700 whitespace-nowrap items-end text-right">
        <div className="flex flex-row items-center justify-between ">
          <div className="text-right ">
            <Link to={`requested-details/${directoryRecordKey(record)}`}>
              <span className="inline-block sm:hidden justify-center items-center">
                <ArrowRightCircleIcon className="h-6 w-6" aria-hidden="true" />
              </span>
              <span className="hidden sm:inline-block text-blue-link">
                Details
              </span>
            </Link>
          </div>
          <VoteTile site={record} />
        </div>
      </div>
    </div>
  );
};
