import * as React from "react";
import { useState } from "react";
import { Site } from "../../context/Sites";
/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
// @ts-ignore
import error from "../../images/error.png";

// const sjcl = require("sjcl");

type IconSize = 180 | 120 | 90 | 60 | 32 | 30 | 16;

// const cfHash = (text: string, secret: string): string => {
//   let textBits = sjcl.codec.utf8String.toBits(text);
//   let secretBits = sjcl.codec.utf8String.toBits(secret);

//   let hmacObj = new sjcl.misc.hmac(secretBits, sjcl.hash.sha256);
//   return sjcl.codec.hex.fromBits(hmacObj.encrypt(textBits));
// };

export const RichIcon: React.FC<{
  record: Site;
  secret?: string | null;
  size?: IconSize;
  height?: number;
  width?: number;
}> = ({ record, secret, size = 60, height = 10, width = 10 }) => {
  const [imageFailed, setImageFailed] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>("");

  // const buildProtectedUrl = (filepath: string, secret: string) => {
  //   const expiration = Math.floor(Date.now() / 1000) + 60;
  //   const rules = `expiretime=${expiration}`;
  //   // const hash = cfHash(rules + filepath, secret); // hexadecimal string

  //   const url = `https://c.1password.com/richicons/images/${rules}/${hash}/${filepath}`;
  //   return url;
  // };

  // const buildImageUrl = () => {
  //   const filepath = `richicons/images/login/${size}/${record.domain.replace(
  //     "https://",
  //     ""
  //   )}.png`;
  //   return secret
  //     ? buildProtectedUrl(filepath, secret)
  //     : `https://cache.agilebits.com/${filepath}`;
  // };

  const buildImageUrl = () =>
    `https://cache.agilebits.com/richicons/images/login/120/${record.domain.replace(
      "https://",
      ""
    )}.png`;

  React.useEffect(() => {
    const url = buildImageUrl();
    if (url) {
      setImageUrl(url);
    } else {
      setImageFailed(true);
    }
  }, []);

  React.useEffect(() => {
    setImageFailed(false);
  }, [imageUrl]);

  return !imageFailed ? (
    <img
      className={`h-${height} w-${width}`}
      src={imageUrl}
      alt={record.name}
      onError={() => setImageFailed(true)}
      
    />
  ) : (
    <img className={`h-${width} w-${height} bg-white`} alt={record.name} src={error} />
  );
};
