import React from "react";

export interface MainSectionContainerProps {
  children: React.ReactNode;
}

export const MainSectionContainer: React.FC<MainSectionContainerProps> = ({
  children,
}) => {
  return <main>{children}</main>;
};
