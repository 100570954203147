import { Database } from "../../../types/supabase";
import { SupabaseClient, PostgrestError } from "@supabase/supabase-js";

export type RequestedSite =
  Database["public"]["Views"]["requested_sites"]["Row"];

export enum REQUESTED_SITES_ORDER_BY {
  NAME = "Name",
  NAME_DESC = "Name (Z to A)",
  CATEGORY = "Category",
  CATEGORY_DESC = "Category (Z to A)",
  VOTES = "Most Votes",
  DATE_RECENT = "Newest First",
  DATE_ORDER = "Oldest First"
}
export interface RequestedSitesStateProps {
  debug: boolean;
  error: Error | string | undefined;
  dbError: PostgrestError | undefined;
  loading: boolean;
  searchTerm: string | undefined | null;
  orderBy: REQUESTED_SITES_ORDER_BY;
  allSites: RequestedSite[];
  sites: RequestedSite[];
  showAllSites: boolean;
}

export type RequestedSitesAction =
  | { type: "FETCH_REQUESTED_SITES" }
  | { type: "FETCH_REQUESTED_SITES_SUCCESS"; sites: RequestedSite[] }
  | {
      type: "FETCH_REQUESTED_SITES_ERROR";
      error: Error | string;
      dbError?: PostgrestError | null | undefined;
    }
  | { type: "SET_SEARCH_TERM"; searchTerm: string | undefined | null }
  | { type: "CLEAR_SEARCH_TERM" }
  | { type: "REQUEST_SITE" }
  | {
      type: "REQUEST_SITE_ERROR";
      error: Error | string;
      dbError?: PostgrestError | undefined;
    }
  | { type: "REQUEST_SITE_SUCCESS" }
  | { type: "REFRESH_REQUESTED_SITES" }
  | { type: "REFRESH_REQUESTED_SITES_SUCCESS"; sites: RequestedSite[] }
  | {
      type: "REFRESH_REQUESTED_SITES_ERROR";
      error: Error | string;
      dbError?: PostgrestError;
    }
  | { type: "TOGGLE_SHOW_ALL_SITES" }
  | { type: "SET_ORDER_BY"; orderBy: REQUESTED_SITES_ORDER_BY };

export interface RequestedSitesContextProps {
  client: SupabaseClient<Database> | undefined | null;
  state: RequestedSitesStateProps;
  dispatch: React.Dispatch<RequestedSitesAction>;
  loadAll: () => Promise<void>;
  clearSearchTerm: () => void;
  setSearchTerm: (searchTerm: string | undefined | null) => void;
  requestSite: (
    site: Pick<RequestedSite, "name" | "domain" | "category">
  ) => Promise<void>;
  refreshSites: () => Promise<void>;
  toggleShowAllSites: () => void;
  setOrderBy: (orderBy: REQUESTED_SITES_ORDER_BY) => void;
}
