import React from "react";
import { SupabaseClient, createClient } from "@supabase/supabase-js";
import { Database } from "../../../types/supabase";
import { SupabaseContext } from "../context/SupabaseContext";

export interface SupabaseProviderProps {
  children: React.ReactNode;
  supabaseKey: string | undefined;
  supabaseUrl: string | undefined;
}

export const SupabaseProvider: React.FC<SupabaseProviderProps> = ({
  supabaseKey,
  supabaseUrl,
  children,
}) => {
  const client: SupabaseClient<Database> | undefined | null =
    supabaseUrl && supabaseKey
      ? createClient<Database>(supabaseUrl, supabaseKey)
      : undefined;

  return (
    <SupabaseContext.Provider value={{ client }}>
      {children}
    </SupabaseContext.Provider>
  );
};

export default SupabaseProvider;
