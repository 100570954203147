import { createContext, useContext } from "react";
import {
  RequestedSitesContextProps,
  RequestedSite,
  REQUESTED_SITES_ORDER_BY,
} from "./types";
import {
  initialRequestedSitesState,
  noopRequestedSitesDispatch,
} from "./RequestedSitesState";

const initialRequestedSitesContext: RequestedSitesContextProps = {
  client: undefined,
  state: initialRequestedSitesState,
  dispatch: noopRequestedSitesDispatch,
  loadAll: async () => {
    return;
  },
  clearSearchTerm: () => {
    return;
  },
  setSearchTerm: (searchTerm: string | undefined | null) => {
    return;
  },
  requestSite: async (
    site: Pick<RequestedSite, "name" | "domain" | "category">
  ) => {
    return;
  },
  refreshSites: async () => {
    return;
  },
  toggleShowAllSites: (): void => {
    return;
  },
  setOrderBy: (orderBy: REQUESTED_SITES_ORDER_BY): void => {
    return;
  },
};

export const RequestedSitesContext = createContext<RequestedSitesContextProps>(
  initialRequestedSitesContext
);

export const useRequestedSites = (): RequestedSitesContextProps => {
  return useContext(RequestedSitesContext);
};

export default useRequestedSites;
