import { Link } from "gatsby";
import React from "react";
import { navigate } from "gatsby";
import { Button, ButtonVariant, Typography } from "../TailWind";
import StayInLoop from "../StayInLoop";

export const RequestSiteSuccess: React.FC = () => {
  const submitAnother = () => {
    navigate("/request-passkey-support");
  };

  return (
    <>
      <div className="z-50 relative bg-white w-full max-w-5xl rounded-xl shadow-lg mx-auto">
        <div className="container">
          <div className="gap-4 flex flex-col sm:flex-row p-4 justify-between ">
            {" "}
            <Link to="/">
              <Button variant={ButtonVariant.Secondary}>
                <svg
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.5 0.292893C7.89052 0.683417 7.89052 1.31658 7.5 1.70711L3.20711 7L7.5 12.2929C7.89052 12.6834 7.89052 13.3166 7.5 13.7071C7.10948 14.0976 6.47631 14.0976 6.08579 13.7071L0.43934 8.06066C-0.146445 7.47487 -0.146448 6.52513 0.43934 5.93934L6.08579 0.292893C6.47631 -0.0976311 7.10948 -0.0976311 7.5 0.292893Z"
                    fill="currentColor"
                  />
                </svg>

                <span>All Listings</span>
              </Button>
            </Link>
          </div>

          <div className="container p-9 text-left">
            <h1 className={Typography.styles.headingPage}>
              Request successfully submitted!
            </h1>
            <span className={Typography.styles.body1}>
              Our team is reviewing your submission. Once approved, it will be
              added to our list.
            </span>
          </div>

          <div className="flex flex-row justify-center pb-10">
            <Button
              variant={ButtonVariant.Primary}
              onClick={(event) => {
                submitAnother();
                event.preventDefault();
              }}
            >
              <span>Submit Another</span>
            </Button>
          </div>
        </div>
      </div>
      <div className="mt-6">
        <StayInLoop />
      </div>
    </>
  );
};
