import React from "react";

export interface DetailsPageWrapperProps {
  children: React.ReactNode;
}

export const DetailsPageWrapper: React.FC<DetailsPageWrapperProps> = ({
  children,
}) => {
  return (
    <div className="z-50 relative bg-white w-full max-w-5xl rounded-xl shadow-lg mx-auto justify-center">
      <div className="container">{children}</div>
    </div>
  );
};
