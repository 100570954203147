import React, { useReducer, useEffect } from "react";
import { Database } from "../../../types/supabase";
import { PasskeySitesContext } from "./PasskeySitesContext";
import { useSupabase } from "../../Supabase";
import {
  passkeySitesReducer,
  initialPasskeySitesState,
} from "./PasskeySitesState";
import { PASSKEY_SITES_FILTER_BY, PASSKEY_SITES_ORDER_BY } from "./types";

export interface PasskeySitesProviderProps {
  children: React.ReactNode;
}

export const PasskeySitesProvider: React.FC<PasskeySitesProviderProps> = ({
  children,
}) => {
  const { client } = useSupabase();

  const [state, dispatch] = useReducer(passkeySitesReducer, {
    ...initialPasskeySitesState,
  });

  const loadAll = async (): Promise<void> => {
    dispatch({ type: "FETCH_PASSKEY_SITES" });
    if (client) {
      const { data, error: dbError } = await client
        .from<"sites", Database["public"]["Tables"]["sites"]>("sites")
        .select("*")
        .eq("hidden",false)
        .or("passkey_signin.eq.true,passkey_mfa.eq.true");
        if (dbError) {
        dispatch({
          type: "FETCH_PASSKEY_SITES_ERROR",
          dbError,
          error: new Error("Data access error."),
        });
      } else {
        dispatch({
          type: "FETCH_PASSKEY_SITES_SUCCESS",
          passkeySites: data || [],
        });
      }
    } else {
      dispatch({
        type: "FETCH_PASSKEY_SITES_ERROR",
        error: new Error("DB Client is undefined"),
      });
    }
  };

  const clearSearchTerm = (): void => {
    dispatch({ type: "CLEAR_SEARCH_TERM" });
  };

  const setSearchTerm = (searchTerm: string | undefined | null): void => {
    dispatch({ type: "SET_SEARCH_TERM", searchTerm });
  };

  const setFilterBy = (filterBy: PASSKEY_SITES_FILTER_BY): void => {
    dispatch({ type: "SET_FILTER_BY", filterBy });
  };

  const setOrderBy = (orderBy: PASSKEY_SITES_ORDER_BY): void => {
    dispatch({ type: "SET_ORDER_BY", orderBy });
  };

  useEffect(() => {
    if (!state.allPasskeySites.length) {
      loadAll();
    }
  }, []);

  return (
    <PasskeySitesContext.Provider
      value={{
        clearSearchTerm,
        client,
        dispatch,
        loadAll,
        setSearchTerm,
        setFilterBy,
        setOrderBy,
        state,
      }}
    >
      {children}
    </PasskeySitesContext.Provider>
  );
};
