import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import {
  RequestedSitesProvider,
  PasskeySitesProvider,
  UserProvider,
  UserDetails,
  SupabaseProvider,
  MainSectionContainer,
} from "../components";
import { FpjsProvider } from "@fingerprintjs/fingerprintjs-pro-react";
import { ApplicationProvider } from "../components/Application";

const gatsbyBranch = process.env.BRANCH;

const supabaseUrl =
  gatsbyBranch && gatsbyBranch !== "main"
    ? process.env.GATSBY_APP_PREVIEW_SUPABASE_URL
    : process.env.GATSBY_APP_SUPABASE_URL;

const supabaseAnonKey =
  gatsbyBranch && gatsbyBranch !== "main"
    ? process.env.GATSBY_APP_PREVIEW_SUPABASE_ANON_KEY
    : process.env.GATSBY_APP_SUPABASE_ANON_KEY;

export const Layout: React.FC<{
  children?: React.ReactNode;
  pageContext: { layout: string };
}> = ({ children, pageContext }) => {
  return (
    <FpjsProvider
      loadOptions={{
        apiKey: process.env.GATSBY_APP_FINGERPRINT_API_KEY,
      }}
    >
      <SupabaseProvider supabaseKey={supabaseAnonKey} supabaseUrl={supabaseUrl}>
        <ApplicationProvider>
          <UserProvider>
            <PasskeySitesProvider>
              <RequestedSitesProvider>
                <MainSectionContainer>
                  <Header
                    variant={pageContext.layout === "root" ? "root" : "root"}
                  />
                  {children}
                  <Footer />
                </MainSectionContainer>
              </RequestedSitesProvider>
            </PasskeySitesProvider>
          </UserProvider>
        </ApplicationProvider>
      </SupabaseProvider>
    </FpjsProvider>
  );
};
export default Layout;
