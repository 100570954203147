import * as React from "react";
import { RichIcon } from "../../RichIcon";
import { Link } from "gatsby";
import { directoryRecordKey } from "../../../utils/utils";
import { ArrowRightCircleIcon } from "@heroicons/react/24/outline";
import { PasskeySite } from "../context";

export const DirectoryRow: React.FC<{ record: PasskeySite }> = ({ record }) => {
  return (
    <tr className="even:bg-slate-50 border-b border-slate-200">
      <td className="pl-4 md:px-4 py-4 whitespace-normal md:whitespace-nowrap">
        <div className="flex items-left flex-col sm:flex-row sm:items-center gap-2">
          <div className="flex-shrink-0 h-10 w-10 rounded-lg overflow-hidden bg-white  aspect-square">
            <RichIcon record={record} />
          </div>
          <div className="sm:ml-4 w-full">
            <div className="text-sm font-medium text-left text-gray-900 truncate max-w-[90%] xs:max-w-[100%]">
              {record.name}
            </div>
            <div className="whitespace-pre-line text-sm md:text-sm text-left text-gray-500 truncate max-w-[90%] xs:max-w-[100%]">
              <a
                href={record.domain_full ? record.domain_full : ""}
                target="_blank"
                className="whitespace-pre-line hover:underline underline-offset-4 truncate max-w-[80%] xs:max-w-[80%]"
              >
                {record.domain}
              </a>
              {/* <a href={`${record.domain_full}?utm_source=passkeys.directory`}>{record.domain}</a> */}
            </div>
          </div>
        </div>
      </td>
      <td className="px-4 py-4 text-sm font-normal text-center sm:text-left text-gray-700 whitespace-normal sm:whitespace-nowrap justify-center gap-1 sm:gap-0 ">
        {record.passkey_signin ? (
          <abbr
            className="md:m-0 m-1 inline-flex items-center text-left px-2 py-1 md:mr-4 text-xs font-medium leading-none text-emerald-800 bg-emerald-100 rounded-full no-underline"
            title="Support for signing in with passkey"
          >
            Sign In
          </abbr>
        ) : (
          ""
        )}
        {record.passkey_mfa ? (
          <abbr
            className="inline-flex items-center text-left px-4 py-1 mr-2  text-xs font-medium leading-none text-amber-700 bg-amber-100 rounded-full no-underline"
            title="Support for passkey as multi-factor authentication method"
          >
            MFA
          </abbr>
        ) : (
          ""
        )}
      </td>
      <td className="hidden sm:table-cell px-2 md:px-4 py-4 text-sm font-normal text-left text-gray-700 whitespace-normal md:whitespace-nowrap">
        {record.category}
      </td>
      <td className="px-2 md:px-4 py-4 text-sm font-normal text-left text-blue-700 whitespace-nowrap hover:underline underline-offset-4 cursor-pointer">
        <Link to={`details/${directoryRecordKey(record)}`}>
          <span className="inline-block sm:hidden justify-center items-center">
            <ArrowRightCircleIcon className="h-6 w-6" aria-hidden="true" />
          </span>
          <span className="hidden sm:inline-block text-blue-link">Details</span>
        </Link>
      </td>
    </tr>
  );
};
