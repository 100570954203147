import * as React from "react";

export const HeroBanner: React.FC = () => {
  return (
    <>
      {/* Hero Banner */}
      <header className="hero relative flex bg-cyan-400 pt-32 pb-16 md:pb-24 w-full flex-1 flex-col flex-wrap items-center justify-center px-2 sm:px-20 text-center overflow-hidden">
        <div className="flex flex-col max-w-5xl md:flex-row md:items-center">
          <div className="flex flex-col text-center sm:text-left justify-center md:justify-left ">
            <h1 className="text-3xl my-2 md:my-0 font-bold text-white z-10 flex flex-wrap text-center md:text-left justify-center md:justify-start  ">
              Passkeys<span className="font-normal">.directory</span>
            </h1>
            <h2 className="text-white z-10 my-1 text-base">
              Websites, apps and services using passkeys for authentication
            </h2>
          </div>
          <h3 className="text-white bg-black z-10 font-normal m-5 py-2 px-4 flex flex-row justify-center items-center flex-wrap gap-0 text-sm h-full">
            Provided by {" "}
            <a href="https://www.1Password.com">
              <span>
              <svg
                width="137"
                height="26"
                viewBox="0 0 137 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1102_17087)">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M26.5 13C26.5 5.82039 20.6796 0 13.5 0C6.32039 0 0.5 5.82039 0.5 13C0.5 20.18 6.32039 26 13.5 26C20.6796 26 26.5 20.18 26.5 13ZM11.1338 5.77783C11.0316 5.97842 11.0316 6.241 11.0316 6.76617V9.7426C11.0316 9.87378 11.0316 9.93938 11.0482 10C11.0629 10.0537 11.087 10.1044 11.1195 10.1497C11.1561 10.2008 11.207 10.2421 11.3088 10.3249L11.3088 10.3249L12.0617 10.9365C12.1844 11.0362 12.2458 11.086 12.2681 11.146C12.2876 11.1987 12.2876 11.2565 12.2681 11.3092C12.2458 11.3692 12.1844 11.419 12.0617 11.5187L11.3088 12.1303C11.207 12.2131 11.1561 12.2544 11.1195 12.3055C11.087 12.3508 11.0629 12.4015 11.0482 12.4552C11.0316 12.5158 11.0316 12.5814 11.0316 12.7126L11.0316 19.2339C11.0316 19.759 11.0316 20.0216 11.1338 20.2222C11.2237 20.3986 11.3672 20.5421 11.5436 20.632C11.7442 20.7342 12.0068 20.7342 12.532 20.7342H14.468C14.9932 20.7342 15.2558 20.7342 15.4564 20.632C15.6328 20.5421 15.7762 20.3986 15.8661 20.2222C15.9683 20.0216 15.9683 19.759 15.9683 19.2339V16.2574C15.9683 16.1262 15.9683 16.0607 15.9518 16C15.9371 15.9463 15.913 15.8956 15.8805 15.8503C15.8439 15.7992 15.793 15.7579 15.6912 15.6752L15.6912 15.6752L14.9383 15.0636L14.9383 15.0635C14.8156 14.9639 14.7542 14.914 14.7319 14.854C14.7124 14.8014 14.7124 14.7435 14.7319 14.6909C14.7542 14.6308 14.8156 14.581 14.9383 14.4813L14.9383 14.4813L15.6912 13.8697C15.793 13.787 15.8439 13.7456 15.8805 13.6945C15.913 13.6493 15.9371 13.5986 15.9518 13.5449C15.9683 13.4842 15.9683 13.4186 15.9683 13.2874V6.76617C15.9683 6.241 15.9683 5.97842 15.8661 5.77783C15.7762 5.60139 15.6328 5.45794 15.4564 5.36804C15.2558 5.26584 14.9932 5.26584 14.468 5.26584H12.532C12.0068 5.26584 11.7442 5.26584 11.5436 5.36804C11.3672 5.45794 11.2237 5.60139 11.1338 5.77783Z" fill="white"/>
                  <path d="M35.9967 20.7134C36.1255 20.7134 36.2299 20.609 36.2299 20.4802V5.23321C36.2299 5.10441 36.1255 5 35.9967 5H33.7382C33.655 5 33.5788 5.04495 33.5329 5.1144C33.0153 5.89812 32.0527 6.29279 30.7334 6.33375C30.6047 6.33775 30.5 6.44172 30.5 6.57052V8.85038C30.5 8.97917 30.6044 9.08358 30.7332 9.08358H32.5588C32.6876 9.08358 32.792 9.18799 32.792 9.31679V20.4802C32.792 20.609 32.8964 20.7134 33.0252 20.7134H35.9967Z" fill="white"/>
                  <path d="M45.0071 8.15224C46.2247 8.15224 47.2035 8.79702 47.2035 10.4209C47.2035 11.997 46.2247 12.6896 45.0071 12.6896H42.357V8.15224H45.0071ZM45.0071 15.6269C48.5883 15.6269 50.6654 13.4537 50.6654 10.3254C50.6654 7.36418 48.5883 5.19104 45.0071 5.19104H39.1761C39.0473 5.19104 38.9429 5.29546 38.9429 5.42425V20.4802C38.9429 20.609 39.0473 20.7134 39.1761 20.7134H42.1238C42.2525 20.7134 42.357 20.609 42.357 20.4802V15.8601C42.357 15.7313 42.4614 15.6269 42.5902 15.6269H45.0071Z" fill="white"/>
                  <path d="M55.9607 18.5164C54.958 18.5164 54.3372 18.0627 54.3372 17.2507C54.3372 16.5343 54.767 16.0567 55.7936 16.0567H58.5869V16.2955C58.5869 17.6806 57.3932 18.5164 55.9607 18.5164ZM58.8495 20.4802C58.8495 20.609 58.9539 20.7134 59.0827 20.7134H61.6484C61.7772 20.7134 61.8816 20.609 61.8816 20.4802V13.7403C61.8816 10.4925 60.0433 8.60597 56.7247 8.60597C54.261 8.60597 52.3747 9.91637 51.6011 12.0231C51.5545 12.15 51.6315 12.2869 51.7628 12.319L54.3475 12.9501C54.4637 12.9784 54.5818 12.914 54.6272 12.8033C55.0195 11.8472 55.6728 11.2806 56.7247 11.2806C57.8229 11.2806 58.5869 11.997 58.5869 13.2866V13.4537C58.5869 13.8119 58.4675 13.9552 58.0378 13.9552H55.0535C52.5705 13.9552 51.0664 15.2687 51.0664 17.3463C51.0664 19.5672 52.7854 21 55.1728 21C56.3753 21 57.5304 20.606 58.344 19.7285C58.5035 19.5565 58.8495 19.6616 58.8495 19.8962V20.4802Z" fill="white"/>
                  <path d="M68.2338 18.6119C67.0734 18.6119 66.2259 18.1321 65.8082 17.2505C65.7576 17.1437 65.6405 17.0815 65.5258 17.1104L63.1098 17.7197C62.9857 17.7509 62.9094 17.8767 62.9464 17.9991C63.5581 20.0212 65.6027 21 68.1144 21C71.3375 21 73.1758 19.3522 73.1758 17.1075C73.1758 12.7612 66.2761 14.2418 66.2761 12.0925C66.2761 11.3761 66.8729 10.9224 67.9473 10.9224C69.0166 10.9224 69.7326 11.5046 70.0757 12.3973C70.1182 12.508 70.2317 12.5774 70.3482 12.5555L72.8141 12.0905C72.9457 12.0657 73.0299 11.935 72.9917 11.8067C72.4263 9.90557 70.8373 8.60597 67.9712 8.60597C64.8197 8.60597 63.1485 10.2776 63.1485 12.3791C63.1485 16.8687 70.0721 15.5313 70.0721 17.394C70.0721 18.1343 69.4514 18.6119 68.2338 18.6119Z" fill="white"/>
                  <path d="M78.9597 18.6119C77.7993 18.6119 76.9518 18.1321 76.5341 17.2505C76.4835 17.1437 76.3664 17.0815 76.2517 17.1104L73.8357 17.7197C73.7116 17.7509 73.6353 17.8767 73.6723 17.9991C74.284 20.0212 76.3286 21 78.8403 21C82.0634 21 83.9017 19.3522 83.9017 17.1075C83.9017 12.7612 77.0019 14.2418 77.0019 12.0925C77.0019 11.3761 77.5988 10.9224 78.6732 10.9224C79.7425 10.9224 80.4585 11.5046 80.8016 12.3973C80.8441 12.508 80.9576 12.5774 81.0741 12.5555L83.54 12.0905C83.6716 12.0657 83.7558 11.935 83.7176 11.8067C83.1522 9.90557 81.5632 8.60597 78.6971 8.60597C75.5456 8.60597 73.8744 10.2776 73.8744 12.3791C73.8744 16.8687 80.798 15.5313 80.798 17.394C80.798 18.1343 80.1773 18.6119 78.9597 18.6119Z" fill="white"/>
                  <path d="M86.9198 20.5356C86.9453 20.64 87.0389 20.7134 87.1464 20.7134H90.8613C90.9695 20.7134 91.0634 20.6391 91.0883 20.5338L92.9797 12.5224L94.8712 20.5338C94.896 20.6391 94.99 20.7134 95.0981 20.7134H98.9328C99.0401 20.7134 99.1336 20.6402 99.1592 20.536L101.957 9.18154C101.993 9.03454 101.882 8.89254 101.731 8.89254H99.0904C98.9799 8.89254 98.8845 8.97011 98.8621 9.07831L96.9907 18.0866L94.6721 9.06768C94.6456 8.9646 94.5527 8.89254 94.4462 8.89254H91.4925C91.3846 8.89254 91.2908 8.96659 91.2657 9.07156L89.112 18.0866L87.2641 9.07888C87.2419 8.97041 87.1464 8.89254 87.0357 8.89254H84.3715C84.2203 8.89254 84.1091 9.03422 84.145 9.18111L86.9198 20.5356Z" fill="white"/>
                  <path d="M108.265 21C111.846 21 114.305 18.2299 114.305 14.8149C114.305 11.3761 111.846 8.60597 108.265 8.60597C104.708 8.60597 102.225 11.3761 102.225 14.8149C102.225 18.2299 104.708 21 108.265 21ZM108.265 18.1582C106.785 18.1582 105.639 16.9164 105.639 14.8149C105.639 12.7134 106.785 11.4478 108.265 11.4478C109.745 11.4478 110.915 12.7134 110.915 14.8149C110.915 16.9164 109.745 18.1582 108.265 18.1582Z" fill="white"/>
                  <path d="M119.014 14.4567C119.014 12.4269 120.041 11.9493 121.617 11.9493C121.919 11.9493 122.251 11.9792 122.566 12.0391C122.699 12.0643 122.83 11.9795 122.854 11.8465L123.354 9.03478C123.371 8.93564 123.324 8.8351 123.231 8.79579C122.944 8.67403 122.589 8.60597 122.166 8.60597C120.566 8.60597 119.325 9.53731 118.728 11.4239V9.12574C118.728 8.99695 118.623 8.89254 118.495 8.89254H115.905C115.776 8.89254 115.672 8.99695 115.672 9.12574V20.4802C115.672 20.609 115.776 20.7134 115.905 20.7134H118.781C118.91 20.7134 119.014 20.609 119.014 20.4802V14.4567Z" fill="white"/>
                  <path d="M129.531 18.2299C127.812 18.2299 126.905 16.8209 126.905 14.8149C126.905 12.809 127.812 11.3761 129.531 11.3761C131.25 11.3761 132.158 12.809 132.158 14.8149C132.158 16.8209 131.25 18.2299 129.531 18.2299ZM132.349 20.4802C132.349 20.609 132.453 20.7134 132.582 20.7134H135.267C135.396 20.7134 135.5 20.609 135.5 20.4802V5.45898C135.5 5.33018 135.396 5.22577 135.267 5.22577H132.486C132.357 5.22577 132.253 5.33018 132.253 5.45898V10.5403C131.513 9.15522 130.271 8.60597 128.696 8.60597C125.616 8.60597 123.515 11.2806 123.515 14.8149C123.515 18.3493 125.616 21 128.696 21C130.367 21 131.656 20.3313 132.349 18.6119V20.4802Z" fill="white"/>
                </g>
                <defs>
                  <clipPath id="clip0_1102_17087">
                    <rect width="136" height="26" fill="white" transform="translate(0.5)"/>
                  </clipPath>
                </defs>
                </svg>
              </span>
            </a>
          </h3>
        </div>
        <div className="gradient-map"></div>
      </header>
    </>
  );
};

export default HeroBanner;
