import React, { useEffect } from "react";
import { useRequestedSites } from "./context";
import { Link } from "gatsby";
import { ActionSection, Button, ButtonVariant, PageSection } from "../TailWind";
import StayInLoop from "../StayInLoop";
import { RequestedSitesList } from "./RequestedSitesList";

export const RequestedSitesContainer: React.FC = () => {
  const {
    loadAll,
    state: { searchTerm, sites, allSites },
  } = useRequestedSites();

  useEffect(() => {
    if (!allSites.length) {
      loadAll();
    }
  }, []);

  return (
    <>
      <div className="mb-5 ">
        <ActionSection
          title="Request passkeys support for your favorite apps and websites"
          description="Upvote to show your interest in passkeys support for your favorite website, app, or service. Can’t find it in the list? Suggest a new entry!"
        >
          <Link to="/request-passkey-support">
            <Button variant={ButtonVariant.Primary}>
              <svg
                className="text-white w-4 h-4"
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  width="16"
                  height="16"
                  transform="translate(0.5)"
                  fill="currentColor"
                  fillOpacity="0.01"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.5 2C7.94772 2 7.5 2.44772 7.5 3V7H3.5C2.94772 7 2.5 7.44772 2.5 8C2.5 8.55228 2.94772 9 3.5 9H7.5V13C7.5 13.5523 7.94772 14 8.5 14C9.05228 14 9.5 13.5523 9.5 13V9H13.5C14.0523 9 14.5 8.55228 14.5 8C14.5 7.44772 14.0523 7 13.5 7H9.5V3C9.5 2.44772 9.05228 2 8.5 2Z"
                  fill="currentColor"
                />
              </svg>
              <span>Add your request</span>
            </Button>
          </Link>
        </ActionSection>
      </div>
      <PageSection>
        <RequestedSitesList />
      </PageSection>

      <StayInLoop />
    </>
  );
};
