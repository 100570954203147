import React from "react";

export enum PAGE_CONTAINER_VARIANT {
  MAIN = "main",
  DETAILS = "details",
}

export interface PageContainerProps {
  children: React.ReactNode;
  variant: PAGE_CONTAINER_VARIANT;
}

export const PageContainer: React.FC<PageContainerProps> = ({
  children,
  variant,
}) => {
  if (variant === PAGE_CONTAINER_VARIANT.DETAILS) {
    return (
      <div className="flex w-full max-w-2xl mx-auto mb-12 flex-1 bg-gray-100 flex-col items-center justify-center px-2 sm:px-8 z-50 text-center">
        <div className="main-details z-50 relative w-full max-w-5xl  mx-auto">
          {children}
        </div>
      </div>
    );
  }

  return (
    <div className="flex w-full flex-1 bg-gray-100 flex-col items-center justify-center px sm:px-2 md:px-8 z-50 text-center">
      <div className="main container xl:max-w-5xl w-full">{children}</div>
    </div>
  );
};
