import { Database } from "../../../types/supabase";
import { SupabaseClient, PostgrestError } from "@supabase/supabase-js";

export type PasskeySite = Database["public"]["Tables"]["sites"]["Row"];

export enum PASSKEY_SITES_FILTER_BY {
  ALL = "All listings",
  MFA = "MFA supported",
  SIGN_IN = "Sign in only",
}

export enum PASSKEY_SITES_ORDER_BY {
  NAME = "Name",
  NAME_DESC = "Name (Z to A)",
  CATEGORY = "Category",
  CATEGORY_DESC = "Category (Z to A)",
  DATE_RECENT = "Newest First",
  DATE_ORDER = "Oldest First"
}

export interface PasskeySitesStateProps {
  debug: boolean;
  error: Error | string | undefined;
  dbError: PostgrestError | undefined;
  loading: boolean;
  searchTerm: string | undefined | null;
  filterBy: PASSKEY_SITES_FILTER_BY;
  orderBy: PASSKEY_SITES_ORDER_BY;
  allPasskeySites: PasskeySite[];
  passkeySites: PasskeySite[];
}

export type PasskeySitesAction =
  | { type: "FETCH_PASSKEY_SITES" }
  | { type: "FETCH_PASSKEY_SITES_SUCCESS"; passkeySites: PasskeySite[] }
  | {
      type: "FETCH_PASSKEY_SITES_ERROR";
      error: Error | string;
      dbError?: PostgrestError | null | undefined;
    }
  | { type: "SET_SEARCH_TERM"; searchTerm: string | undefined | null }
  | { type: "CLEAR_SEARCH_TERM" }
  | { type: "SET_FILTER_BY"; filterBy: PASSKEY_SITES_FILTER_BY }
  | { type: "SET_ORDER_BY"; orderBy: PASSKEY_SITES_ORDER_BY };

export interface PasskeySitesContextProps {
  client: SupabaseClient<Database> | undefined | null;
  state: PasskeySitesStateProps;
  dispatch: React.Dispatch<PasskeySitesAction>;
  loadAll: () => Promise<void>;
  clearSearchTerm: () => void;
  setSearchTerm: (searchTerm: string | undefined | null) => void;
  setFilterBy: (filterBy: PASSKEY_SITES_FILTER_BY) => void;
  setOrderBy: (orderBy: PASSKEY_SITES_ORDER_BY) => void;
}
