import React from "react";

export interface ActionSectionProps {
  title: string;
  description: string;
  children: React.ReactNode;
}

export const ActionSection: React.FC<ActionSectionProps> = ({
  title,
  description,
  children,
}) => {
  return (
    <div className="bg-slate-200 rounded-2xl mx-4 p-5">
      <div className="flex flex-col sm:flex-row items-center gap-4 sm:gap-2">
        <div className="flex-1 flex-col justify-items-center gap-4 sm:justify-items-start flex-wrap">
          <div className="text-center text-sm font sm:text-left font-bold text-gray-900">
            {title}
          </div>
          <div className="text-center text-sm sm:text-left flex-wrap text-gray-900">
            {description}
          </div>
        </div>
        <div className="flex-none text-sm">{children}</div>
      </div>
    </div>
  );
};

export default ActionSection;
