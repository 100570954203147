import React, { useEffect } from "react";
import { DirectoryTable } from "./DirectoryTable";
import { OutboundLink } from "gatsby-plugin-gtag";
import { usePasskeySites } from "../context";
import {
  ActionSection,
  Button,
  ButtonVariant,
  PageSection,
} from "../../TailWind";
import StayInLoop from "../../StayInLoop";

export const DirectoryContainer: React.FC = () => {
  const {
    loadAll: fetchAll,
    state: { searchTerm, passkeySites: sites, allPasskeySites: allSites },
  } = usePasskeySites();

  useEffect(() => {
    if (!allSites.length) {
      fetchAll();
    }
  }, []);

  return (
    <>
      <div className="mb-5 mx-4 sm:mx-0 ">
        <ActionSection
          title="Suggest a missing app or service"
          description="Found a website, app, or service that offers passkeys support but
            isn't on this list? Use the suggestion form to contribute a new
            listing."
        >
          <OutboundLink
            href="https://docs.google.com/forms/d/e/1FAIpQLSfsTxXH0jWA3Op4Qbte3tdn2OR0zs8ixLKljWswSI-Gi3V80A/viewform"
            target="_blank"
          >
            <Button variant={ButtonVariant.Primary}>
              <svg
                className="text-white w-4 h-4"
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  width="16"
                  height="16"
                  transform="translate(0.5)"
                  fill="currentColor"
                  fillOpacity="0.01"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.5 2C7.94772 2 7.5 2.44772 7.5 3V7H3.5C2.94772 7 2.5 7.44772 2.5 8C2.5 8.55228 2.94772 9 3.5 9H7.5V13C7.5 13.5523 7.94772 14 8.5 14C9.05228 14 9.5 13.5523 9.5 13V9H13.5C14.0523 9 14.5 8.55228 14.5 8C14.5 7.44772 14.0523 7 13.5 7H9.5V3C9.5 2.44772 9.05228 2 8.5 2Z"
                  fill="currentColor"
                />
              </svg>
              <span>Suggest new listing</span>
            </Button>
          </OutboundLink>
        </ActionSection>
      </div>

      <PageSection>
        <DirectoryTable />
      </PageSection>

      <StayInLoop />
    </>
  );
};
