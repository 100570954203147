import * as React from "react";
import { Banner, RootBanner } from "./Banner";
import { HeroBanner, RootHeroBanner } from "./HeroBanner";

export interface HeaderProps {
  variant: "root" | "standard";
}

export const Header: React.FC<HeaderProps> = ({ variant }) => {
  if (variant === "root") {
    return (
      <>
        <RootBanner />
        <RootHeroBanner />
      </>
    );
  }

  return (
    <>
      <Banner />
      <HeroBanner />
    </>
  );
};

export default Header;
