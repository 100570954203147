import React from "react";
import {
  ApplicationAction,
  ApplicationStateProps,
  DirectoryTab,
} from "./types";

export const noopApplicationDispatch: React.Dispatch<
  ApplicationAction
> = () => {
  return null;
};

export const initialApplicationState: ApplicationStateProps = {
  debug: false,
  error: undefined,
  loading: false,
  directoryTab: DirectoryTab.PasskeySites,
};

export const applicationReducer = (
  state: ApplicationStateProps,
  action: ApplicationAction
) => {
  if (state.debug) {
    console.log("DISPATCH[applicationReducer]: action:", action);
  }

  switch (action.type) {
    case "SELECT_TAB":
      return {
        ...state,
        directoryTab: action.tab,
      };
    default:
      return { ...state };
  }
};

export default applicationReducer;
