import React, { useEffect } from "react";
import { RichIcon } from "../RichIcon";
import { Link } from "gatsby";
import { directoryRecordKey } from "../../utils/utils";
import { ArrowRightCircleIcon } from "@heroicons/react/24/outline";
import { RequestedSite, useRequestedSites } from "./context";
import { UserVote, useUser } from "../User";
import { usePasskeySites } from "../PasskeySites";

export interface VoteTileProps {
  site: RequestedSite;
}

export const VoteTile: React.FC<VoteTileProps> = ({ site }) => {
  const {
    state: { votes, user },
    castVote,
    unvote,
  } = useUser();
  const { refreshSites } = useRequestedSites();

  const [vote, setVote] = React.useState<UserVote | undefined>(undefined);

  useEffect(() => {
    setVote(
      votes.find((v) => {
        return v.site_id === site.id;
      })
    );
  }, [votes]);

  const voteForSite = async (a: unknown): Promise<void> => {
    if (user) {
      try {
        const vote = await castVote(user, site);
        if (vote) {
          setVote(vote);
          refreshSites();
        }
      } catch (error) {
        console.error(error);
      }

      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      // @ts-ignore
      a.preventDefault();
    }
  };

  const unvoteForSite = async (a: unknown): Promise<void> => {
    if (vote) {
      try {
        await unvote(vote);
        setVote(undefined);
        refreshSites();
      } catch (error) {
        console.error(error);
      }
    }
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    a.preventDefault();
  };

  if (vote) {
    return (
      <div
        className="border border-vote-green rounded p-2 flex flex-col justify-center items-center h-11 w-14 bg-green-50 text-xs text-green-700 hover:cursor-pointer font-bold "
        onClick={unvoteForSite}
      >
        <div className="mb-1">
          <svg
            width="14"
            height="12"
            viewBox="0 0 14 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.5621 0.25044C14.0652 0.644678 14.1468 1.36376 13.7443 1.85656L6.09648 11.5711C5.88917 11.8249 5.58133 11.9803 5.24998 11.9982C4.91864 12.0162 4.59517 11.8951 4.36051 11.6653L0.341708 7.66416C-0.113903 7.21791 -0.113903 6.4944 0.341708 6.04816C0.774013 5.62474 1.46118 5.60308 1.91956 5.98318C1.96921 6.02436 2.01876 6.066 2.07266 6.10144L4.24829 7.53198C4.65771 7.80119 5.20189 7.73408 5.53364 7.37346L11.9223 0.428898C12.3248 -0.0638991 13.059 -0.143797 13.5621 0.25044Z"
              fill="#84CC16"
            />
          </svg>
        </div>
        <span>{site.count ? site.count : "0"}</span>
      </div>
    );
  }

  return (
    <div
      className="border  border-vote-blue rounded p-2 hover:cursor-pointer flex flex-col justify-center items-center h-11 w-14 text-xs text-blue-700 font-bold hover:bg-blue-50"
      onClick={voteForSite}
    >
      <div className="mb-1">
        <svg
          width="12"
          height="8"
          viewBox="0 0 12 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.16159 0.363636C5.53422 -0.121212 6.46578 -0.121212 6.83841 0.363637L11.8689 6.90909C12.2415 7.39394 11.7757 8 11.0304 8H0.969553C0.224302 8 -0.241479 7.39394 0.131147 6.90909L5.16159 0.363636Z"
            fill="#0364D3"
          />
        </svg>
      </div>

      {/* {vote && "Already Voted"}
            {!vote && <button onClick={voteForSite}>Cast Vote</button>} */}
      <span>{site.count ? site.count : "0"}</span>
    </div>
  );
};
