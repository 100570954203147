import React, { createContext, useContext } from "react";
import { SupabaseClient } from "@supabase/supabase-js";
import { Database } from "../../types/supabase";

export interface SupabaseContextProps {
  client: SupabaseClient<Database> | undefined | null;
}

const iniitalSupabaseContext: SupabaseContextProps = {
  client: undefined,
};

export const SupabaseContext = createContext<SupabaseContextProps>(
  iniitalSupabaseContext
);

export const useSupabase = (): SupabaseContextProps => {
  return useContext(SupabaseContext);
};
