import React, { useReducer, useRef, useState, useEffect } from "react";
import { Database } from "../../../types/supabase";
import { UserContext } from "./UserContext";
import { useSupabase } from "../../Supabase";
import { userReducer, initialUserState } from "./UserState";
import { useVisitorData } from "@fingerprintjs/fingerprintjs-pro-react";
import { SupabaseClient, PostgrestError } from "@supabase/supabase-js";
import { SupabaseAuthUser, UserProfile, UserVote, VisitorData } from "./types";
import { RequestedSite } from "../../RequestedSites";
import { useUserSql } from "./useUserSql";
import { useSupabaseAuth } from "./useSupabaseAuth";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

export interface UserProviderProps {
  children: React.ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const { client } = useSupabase();
  const _sql = useUserSql(client);
  const _auth = useSupabaseAuth(client);
  const [state, dispatch] = useReducer(userReducer, {
    ...initialUserState,
  });
  const {
    isLoading: visitorIsLoading,
    error: visitorError,
    data: visitorData,
  } = useVisitorData();

  const prevVisitorIsLoading = useRef<boolean>(visitorIsLoading || false);
  const prevVisitorError = useRef<Error | undefined>(visitorError);
  const prevVisitorData = useRef<unknown>(visitorData);

  useEffect(() => {
    const attemptFingerprintBackup = async () => {
      const fpPromise = FingerprintJS.load();
      const fp = await fpPromise;
      const result = await fp.get();

      if (result.visitorId) {
        dispatch({
          type: "FINGERPRINT_LOAD_SUCCESS",
          visitor: { visitorId: result.visitorId },
        });
      } else {
        dispatch({
          type: "FINGERPRINT_LOAD_ERROR",
          error: visitorError || new Error("Failed to load visitor"),
        });
      }
    };
    if ((visitorIsLoading || false) !== prevVisitorIsLoading.current) {
      if (visitorIsLoading) {
        dispatch({ type: "FINGERPRINT_LOADING" });
      } else {
        if (visitorError || !visitorData) {
          attemptFingerprintBackup();
        } else {
          dispatch({ type: "FINGERPRINT_LOAD_SUCCESS", visitor: visitorData });
        }
      }
    }
    prevVisitorIsLoading.current = visitorIsLoading || false;
    prevVisitorError.current = visitorError;
    prevVisitorData.current = visitorData;
  }, [visitorIsLoading, visitorError, visitorData]);

  // const {
  //   state: { loading: visitorLoading, error: visitorError, visitor },
  // } = useVisitor();

  // load or create the user once we have the visitor
  useEffect(() => {
    if (state.visitor) {
      loadOrCreateUser(state.visitor);
    }
  }, [state.visitor]);

  useEffect(() => {
    if (state.user) {
      loadUserVotes(state.user);
    }
  }, [state.user]);

  // ---------------------------------------------------
  // Private methods
  // ---------------------------------------------------

  const loadOrCreateUser = async (visitor: VisitorData): Promise<void> => {
    dispatch({ type: "AUTH_OR_CREATE_USER" });
    let authUser: SupabaseAuthUser | undefined;
    let newSignup = false;
    try {
      authUser = await _auth.signIn(visitor);
      dispatch({ type: "AUTH_OR_CREATE_USER_SUCCESS", authUser });
      newSignup = false;
    } catch (error) {
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      // @ts-ignore
      // dispatch({ type: 'AUTH_OR_CREATE_USER_ERROR', error });
      authUser = undefined;
    }

    if (!authUser) {
      try {
        authUser = await _auth.signUp(visitor);
        if (!authUser) {
          throw new Error("Invalid user.");
        }
        newSignup = true;
        dispatch({ type: "AUTH_OR_CREATE_USER_SUCCESS", authUser });
      } catch (error) {
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        dispatch({ type: "AUTH_OR_CREATE_USER_ERROR", error });
      }
    }

    // load or create user profile
    if (newSignup) {
      // create the user profile
      dispatch({ type: "CREATE_USER_PROFILE" });
      try {
        const user = await _sql.insertUser(visitor);
        if (!user) {
          throw new Error("invalid user.");
        }
        dispatch({ type: "CREATE_USER_PROFILE_SUCCESS", user });
      } catch (error) {
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        dispatch({ type: "CREATE_USER_PROFILE_ERROR", error });
      }
    } else {
      // load the user profile
      dispatch({ type: "LOAD_USER_PROFILE" });
      try {
        const user = await _sql.fetchUser(visitor);
        if (!user) {
          throw new Error("invalid user");
        }
        dispatch({ type: "LOAD_USER_PROFILE_SUCCESS", user });
      } catch (error) {
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        dispatch({ type: "LOAD_USER_PROFILE_ERROR", error });
      }
    }
  };

  const loadUser = async (): Promise<void> => {
    dispatch({ type: "LOAD_USER_PROFILE" });
    if (!state.visitor) {
      dispatch({
        type: "LOAD_USER_PROFILE_ERROR",
        error: new Error("invalid visitor"),
      });
      return;
    }
    try {
      const user = await _sql.fetchUser(state.visitor);

      if (!user) {
        throw new Error("invalid user");
      }

      dispatch({ type: "LOAD_USER_PROFILE_SUCCESS", user });
    } catch (error: unknown) {
      dispatch({
        type: "LOAD_USER_PROFILE_ERROR",
        error:
          error instanceof Error ? error : new Error("Error loading user."),
      });
    }
  };

  const createUser = async (): Promise<void> => {
    dispatch({ type: "CREATE_USER_PROFILE" });
    if (!state.visitor) {
      dispatch({
        type: "LOAD_USER_PROFILE_ERROR",
        error: new Error("invalid visitor"),
      });
      return;
    }
    try {
      const user = await _sql.insertUser(state.visitor);

      if (!user) {
        throw new Error("invalid user");
      }

      dispatch({ type: "CREATE_USER_PROFILE_SUCCESS", user });
    } catch (error: unknown) {
      dispatch({
        type: "CREATE_USER_PROFILE_ERROR",
        error:
          error instanceof Error ? error : new Error("Error loading user."),
      });
    }
  };

  const loadUserVotes = async (user: UserProfile): Promise<void> => {
    dispatch({ type: "LOAD_VOTES" });
    try {
      const votes = await _sql.fetchVotes(user);

      dispatch({ type: "LOAD_VOTES_SUCCESS", votes });
    } catch (error: unknown) {
      dispatch({
        type: "LOAD_VOTES_ERROR",
        error:
          error instanceof Error ? error : new Error("Error loading votes."),
      });
    }
  };

  const castVote = async (
    user: UserProfile,
    site: RequestedSite
  ): Promise<UserVote> => {
    dispatch({ type: "CAST_VOTE" });
    try {
      const vote = await _sql.insertVote(user, site);
      if (!vote) {
        throw new Error("invalid vote");
      }
      dispatch({ type: "CAST_VOTE_SUCCESS", vote });
      return vote;
    } catch (error: unknown) {
      dispatch({
        type: "LOAD_VOTES_ERROR",
        error:
          error instanceof Error ? error : new Error("Error loading votes."),
      });
      throw error;
    }
  };

  const unvote = async (vote: UserVote): Promise<void> => {
    dispatch({ type: "UNVOTE" });
    try {
      await _sql.deleteVote(vote);
      dispatch({ type: "UNVOTE_SUCCESS", vote });
    } catch (error: unknown) {
      dispatch({
        type: "UNVOTE_ERROR",
        error:
          error instanceof Error ? error : new Error("Error loading votes."),
      });
    }
  };

  return (
    <UserContext.Provider
      value={{
        client,
        dispatch,
        state,
        loadUser,
        createUser,
        loadOrCreateUser,
        loadUserVotes,
        castVote,
        unvote,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
